import React, { useLayoutEffect, useRef } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface AnimationSeparateUppercaseTextProps {
  text: string;
  textAlign?: string;
  mobTextAlign?: string;
}

export const AnimationSeparateUppercaseText: React.FC<AnimationSeparateUppercaseTextProps> = ({
  text,
  textAlign,
  mobTextAlign,
}) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (textRef.current) {
        const tl1 = gsap.timeline({
          scrollTrigger: {
            trigger: textRef.current,
            start: 'top 120%',
            end: 'top 60%',
            scrub: 3,
            invalidateOnRefresh: true,
            once: true,
          },
        });

        tl1
          .add('start')
          .fromTo(textRef.current, { y: '100%' }, { y: '0%', duration: 0.5, ease: 'power3.out' }, 'start')
          .fromTo(
            textRef.current,
            {
              clipPath: 'inset(0% 0% 100% 0%)',
              opacity: 1,
            },
            {
              clipPath: 'inset(0% 0% 0% 0%)',
              duration: 0.5,
              ease: 'power3.inOut',
            },
            'start'
          );
      }
    });

    return () => ctx.revert();
  }, []);

  return (
    <p {...stylex.props(styles.text(textAlign, mobTextAlign))} ref={textRef}>
      {text}
    </p>
  );
};
