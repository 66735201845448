import React from 'react';
import { renderComponent } from '@/shared/libs/dynamic-components-render.tsx';
import { pageConfig } from '../model/mock-main-page';

export const MainPage = () => {
  return (
    <div>
      {pageConfig().map((config, index) => (
        <React.Fragment key={index}>{renderComponent(config)}</React.Fragment>
      ))}
    </div>
  );
};
