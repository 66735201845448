import styles from '@/features/infoblocks-for-progress-bar/ui/infoblocks-for-pb.module.css';
import { IInfoBlocksForProgressBar } from '../model/types';
import gsap from 'gsap';
import { useLayoutEffect, useRef } from 'react';
export const InfoBlocksForProgressBar = ({ content }: IInfoBlocksForProgressBar) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (wrapperRef.current && headerRef.current) {
      // Создаем GSAP timeline для последовательной анимации
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: 'top 80%',
          end: 'top 20%',
          scrub: 3,
          once: true,
        },
      });

      // Анимация движения снизу вверх
      tl.fromTo(
        headerRef.current,
        { y: '100%' },
        { y: '0%', duration: 0.5, ease: 'power3.out' },
        0 // Начинаем эту анимацию в начале timeline
      );

      // Анимация проявления текста сверху вниз
      tl.fromTo(
        headerRef.current,
        {
          clipPath: 'inset(0% 0% 100% 0%)', // Начинаем с полностью скрытого текста
          opacity: 1, // Устанавливаем opacity в 1, так как будем использовать clipPath для эффекта появления
        },
        {
          clipPath: 'inset(0% 0% 0% 0%)', // Заканчиваем полностью видимым текстом
          duration: 0.5,
          ease: 'power3.inOut',
        },
        0 // Начинаем эту анимацию одновременно с предыдущей
      );
    }
  }, []);
  return (
    <div ref={wrapperRef} className={styles.container}>
      <div ref={headerRef} className={styles.header}>
        <p className={styles.headerText}>{content.headerText}</p>
      </div>
      <div className={styles.content}>
        <div className={styles.contentImg}>
          <img src={content.imageUrl}></img>
        </div>
        <div className={styles.textContainer}>
          <p className={styles.contentText}>{content.contentText}</p>
        </div>
      </div>
      <div className={styles.footer}>
        <p className={styles.footerText}>{content.footerText}</p>
      </div>
    </div>
  );
};
