import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

export const Separator: React.FC = () => {
  return (
    <span {...stylex.props(styles.separator)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1 152" fill="none">
        <path d="M1 0L0.999993 152" stroke="currentColor" />
      </svg>
    </span>
  );
};
