import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    width: '100%',
    display: 'flex',
    padding: '0rem 3.125rem 0rem 11.04167rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',

    '@media (max-width: 767px)': {
      padding: '0rem 0rem 0rem 0rem',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      gap: '14.93333rem',
    },
  },
  textContentComponent: {
    '@media (max-width: 767px)': {
      padding: '0rem 4.26667rem 0rem 4.26667rem',
    },
  },
  imageWithCaption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1.04167rem',

    '@media (max-width: 767px)': {
      alignItems: 'flex-end',
      gap: '3.2rem',
      justifyContent: 'center',
    },
  },
  image: {
    alignSelf: 'stretch',
    width: '46.25rem',
    height: '29.58333rem',
    borderRadius: '0rem 0rem 0rem 2.91667rem',
    objectFit: 'cover',
    zIndex: '1',

    '@media (max-width: 767px)': {
      width: '96rem',
      height: '83.2rem',
      borderRadius: '0rem 0rem 0rem 14.93333rem',
    },
  },
  captionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'stretch',

    '@media (max-width: 767px)': {
      paddingRight: '4.26667rem',
      justifyContent: 'center',
      alignItems: 'flex-end',
      alignSelf: 'auto',
    },
  },
  caption: {
    color: colors.secondaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.25rem',

    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
      lineHeight: '4.26667rem',
    },
  },
});
