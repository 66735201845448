import styles from './full-screen-autoplay-video.module.css';

export interface FullScreenAutoplayVideoProps {
  url: string;
  caption?: string;
}
export const FullScreenAutoplayVideo = ({ url, caption }: FullScreenAutoplayVideoProps) => {
  return (
    <div className={styles.wrapper}>
      <video className={styles.video} src={url} autoPlay={true} controls={false} loop playsInline></video>
      <div className={styles.caption}>
        <h1 className={styles.h1}>{caption}</h1>
      </div>
    </div>
  );
};
