import React from 'react';
import styles from './styles.module.css';

interface ImageProps {
  imageSrc?: string;
  isReverse?: boolean;
  imageMask?: 'circle' | 'arch' | 'reversed arch';
}

export const Image: React.FC<ImageProps> = ({ imageSrc, isReverse, imageMask }) => {
  const getImageMask = (imageMask: 'circle' | 'arch' | 'reversed arch') => {
    if (imageMask === 'circle') return '50%';
    if (imageMask === 'arch') return '50% 50% 0 0';
    if (imageMask === 'reversed arch') return '0 0 50% 50%';
  };

  return (
    <>
      {imageSrc && (
        <div
          className={styles.imageBlock}
          style={{
            borderRadius: imageMask ? getImageMask(imageMask) : '',
            order: isReverse ? '2' : '1',
          }}
        >
          <img src={imageSrc} className={styles.image} alt="image" draggable="false" />
        </div>
      )}
    </>
  );
};
