import React from 'react';
import styles from './styles.module.css';
import { SitesImageInCurlyMaskProps } from '../model/types';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { LinkButton } from './link-button';
import { DescriptionWithList } from './description-with-list';
import { Image } from './image';
import { TopDescription } from './top-description';
import { Title } from './title';

/**
 * @component
 *
 * Компонент конструктора.
 *
 * `GSITE-242`
 * `Компонент "Контент с изображением в фигурной маске"`
 *
 */

export const SitesImageInCurlyMask: React.FC<SitesImageInCurlyMaskProps> = ({
  topDescription,
  title,
  mainColor,
  description,
  imageSrc,
  isReverse,
  backgroundColor,
  descriptionColor,
  imageMask,
  linkForButton,
  buttonText,
  listInDescription,
}) => {
  const { isMobile } = useIsMobile();

  return (
    <div className={styles.container} style={{ backgroundColor: backgroundColor ? backgroundColor : '' }}>
      <TopDescription topDescription={topDescription} mainColor={mainColor} />
      <div className={styles.imageWithTextContainer}>
        <Image imageSrc={imageSrc} isReverse={isReverse} imageMask={imageMask} />
        <div className={styles.textBlock} style={{ order: isReverse ? '1' : '2' }}>
          <Title title={title} mainColor={mainColor} />
          <div className={styles.textWithButton}>
            <DescriptionWithList
              description={description}
              descriptionColor={descriptionColor}
              listInDescription={listInDescription}
            />
            {!isMobile ? (
              <LinkButton linkForButton={linkForButton} buttonText={buttonText} mainColor={mainColor} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {isMobile ? <LinkButton linkForButton={linkForButton} buttonText={buttonText} mainColor={mainColor} /> : ''}
    </div>
  );
};
