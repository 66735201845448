import { CommonProps, ComponentConfig } from '@/shared/libs/dynamic-components-render';
import {
  animatedUpperCaseText,
  BEFORE_AFTER_PHOTO_INFO_MOCK,
  contentForAutoplayVideoBlock,
  contentInCircleOfComfortable,
  contentInCircleOfCompany,
  contentInCircleOfProjects,
  dropdownItems,
  imageInBlockWithRedirectToProjects,
  imageInBlockWithStatistics,
  middleUpperCaseText,
  mockFhotos,
  slidesHalfScreen,
  textInBlockWithHalfSlider,
  textInCircleOfComfortable,
  textInCircleOfCompany,
  textInCircleOfProjects,
  textWithAnimatedUpperCaseText,
  textWithMiddleUpperCaseText,
  textWithRedirectToProjects,
  textWithStatistics,
  topUpperCaseText,
} from '../api/mock-data';
import frontOrdynka from '@/shared/ui/assets/bg-images/ordynka-facade.png';
import { TextBlockWithRedirectButton } from '@/pages/main-page/ui/text-block-with-redirect-button';
import * as stylex from '@stylexjs/stylex';
import { styles } from '../ui/styles';

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'section',
    props: { ...stylex.props(styles.pageWrapper) },
    children: [
      {
        component: 'AutoplayVideoWithRedirectAndCaption',
        props: {
          contentForAutoplayVideoBlock,
        },
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.topSeparateUppercaseTextWrapper) },
        children: [
          {
            component: 'SeparateUppercaseText',
            props: { text: topUpperCaseText },
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.textWithHalfSliderWrapper) },
        children: [
          {
            component: 'section',
            props: { ...stylex.props(styles.textInBlockWithHalfSliderWrapper) },
            children: [
              {
                component: 'TextBlockWithRedirectButton',
                props: {
                  content: textInBlockWithHalfSlider,
                },
              },
            ],
          },
          {
            component: 'SliderHalfScreen',
            props: {
              slides: slidesHalfScreen,
              position: 'right',
            },
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.wrapperForCorrectGapCircle100) },
        children: [
          {
            component: 'section',
            props: { ...stylex.props(styles.topCircleProgressBarWrapper) },
            children: [
              {
                component: 'section',
                props: { ...stylex.props(styles.topCircleProgressBarContainer) },
                children: [
                  {
                    component: 'CircleProgressBarMainPage',
                    props: {
                      bottomTextBlock: <TextBlockWithRedirectButton content={textInCircleOfProjects} />,
                      contentBlock: contentInCircleOfProjects,
                    },
                  },
                ],
              },
            ],
          },
          {
            component: 'section',
            props: { ...stylex.props(styles.sectionWithThreeComponents) },
            children: [
              {
                component: 'section',
                props: { ...stylex.props(styles.middleUpperCaseTextWithCaption) },
                children: [
                  {
                    component: 'section',
                    props: { ...stylex.props(styles.middleUpperCaseText) },
                    children: [
                      {
                        component: 'SeparateUppercaseText',
                        props: { text: middleUpperCaseText },
                      },
                    ],
                  },

                  {
                    component: 'TextBlockWithRedirectButton',
                    props: { content: textWithMiddleUpperCaseText },
                  },
                ],
              },
              {
                component: 'section',
                props: { ...stylex.props(styles.BeforeAfterSliderWrapper) },
                children: [
                  {
                    component: 'BeforeAfterSlider',
                    props: {
                      photosInfo: BEFORE_AFTER_PHOTO_INFO_MOCK,
                    },
                  },
                ],
              },
              {
                component: 'TextAndImageWithCaption',
                props: {
                  textContentComponent: <TextBlockWithRedirectButton content={textWithRedirectToProjects} />,
                  image: imageInBlockWithRedirectToProjects,
                },
              },
            ],
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.animatedTextWrapper) },
        children: [
          {
            component: 'section',
            props: { ...stylex.props(styles.animatedUpperCaseText) },
            children: [
              {
                component: 'AnimationSeparateUppercaseText',
                props: { text: animatedUpperCaseText },
              },
            ],
          },
          {
            component: 'section',
            props: { ...stylex.props(styles.textWithAnimatedUpperCaseTextWrapper) },
            children: [
              {
                component: 'TextBlockWithRedirectButton',
                props: { content: textWithAnimatedUpperCaseText },
              },
            ],
          },
        ],
      },
      {
        component: 'DropdownWithImages',
        props: {
          dropdownItems: dropdownItems,
        },
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.wrapperForCorrectGapCircle247) },
        children: [
          {
            component: 'section',
            props: { ...stylex.props(styles.middleCircleProgressBarWrapper) },
            children: [
              {
                component: 'section',
                props: { ...stylex.props(styles.middleCircleProgressBarContainer) },
                children: [
                  {
                    component: 'CircleProgressBarMainPage',
                    props: {
                      bottomTextBlock: <TextBlockWithRedirectButton content={textInCircleOfComfortable} />,
                      contentBlock: contentInCircleOfComfortable,
                    },
                  },
                ],
              },
            ],
          },
          {
            component: 'TextAndImageWithCaption',
            props: {
              textContentComponent: <TextBlockWithRedirectButton content={textWithStatistics} />,
              image: imageInBlockWithStatistics,
            },
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.bottomCircleProgressBarWrapper) },
        children: [
          {
            component: 'section',
            props: { ...stylex.props(styles.bottomCircleProgressBarContainer) },
            children: [
              {
                component: 'CircleProgressBarMainPage',
                props: {
                  bottomTextBlock: <TextBlockWithRedirectButton content={textInCircleOfCompany} />,
                  contentBlock: contentInCircleOfCompany,
                },
              },
            ],
          },
        ],
      },
      {
        component: 'section',
        props: { ...stylex.props(styles.sliderFullScreenWrapper) },
        children: [
          {
            component: 'SliderDefault',
            props: {
              images: mockFhotos,
            },
          },
        ],
      },
    ],
  },
  {
    component: 'TransitionCard',
    props: {
      bgUrl: frontOrdynka,
      title: 'СОЗДАЁМ УНИКАЛЬНЫЕ ПРОЕКТЫ',
      link: '/fine-development',
    },
  },
];
