import AndroidAppQr from '@/shared/ui/icons/AndroidAppQr';
import { AppStore } from '@/shared/ui/icons/AppStore';
import { GooglePlay } from '@/shared/ui/icons/GooglePlay';
import IosAppQr from '@/shared/ui/icons/IosAppQr';
import renomeLogo from '@/shared/ui/assets/reality-page/renome-logo.svg';
import renomeFacade11 from '@/shared/ui/assets/reality-page/renome-facade-1-1.jpg';
import renomeFacade12 from '@/shared/ui/assets/reality-page/renome-facade-1-2.jpg';
import sminexDoor from '@/shared/ui/assets/sluzba-komforta-page/sminex-door.png';
import { SliderPhotoType } from '@/features/slider-default/model/types';
import clock from '@shared/ui/assets/sluzba-komforta-page/clock.jpg';
import yard from '@shared/ui/assets/sluzba-komforta-page/yard.jpg';
import vnimanieizabota from '@shared/ui/assets/sluzba-komforta-page/vnimanieizabota.png';
export const blocks = [
  {
    header: 'СЛЫШИМ ВАС',
    details:
      'Для нас важно, чтобы в доме всё было именно так, как хотят клиенты, и мы прислушиваемся к их пожеланиям, проводим живые опросы и онлайн-голосования в мобильном приложении.',
  },
  {
    header: 'ВСЕГДА НА СВЯЗИ',
    details:
      'Для удобства клиентов мы принимаем обращения (заказы услуг, жалобы, предложения) по телефону, через консьержа и в мобильном приложении.',
  },
  {
    header: 'СОВЕРШЕНСТВУЕМСЯ',
    details:
      'Мы совершенствуем существующие сервисы и внедряем новые. Скачать наше приложение Sminex Comfort просто: наведите камеру вашего смартфона на QR-код.',
  },
];

export const url = 'https://www.sminex.com/bitrix/templates/sminex/video/Sminex_mobile_new.mp4';

export const header = `Фасад дома «Реномэ»`;

export const qrCodes = [
  {
    svg: <AndroidAppQr />,
    name: 'ANDROID',
    btn: <GooglePlay />,
    url: 'https://play.google.com/store/apps/details?id=sminex.ru.sminex_app',
  },
  {
    svg: <IosAppQr />,
    name: 'IOS',
    btn: <AppStore />,
    url: 'https://apps.apple.com/am/app/sminex-comfort/id1350615149',
  },
];

export const BEFORE_AFTER_MOCK = [
  {
    id: 7,
    buildingName: 'Реномэ',
    logoImg: renomeLogo,
    isDisabled: false,
    photos: [{ id: 8, title: 'Фасад', beforeImg: renomeFacade11, afterImg: renomeFacade12 }],
  },
];

export const mockSlides: SliderPhotoType[] = [
  {
    id: 1,
    imgSrc: clock,
    alt: `${clock}'s photo`,
    caption: `Дом «Реномэ»`,
  },
  {
    id: 2,
    imgSrc: yard,
    alt: `${yard}'s photo`,
    caption: `Дом «Реномэ»`,
  },
];

export const BEFORE_AFTER_MOCK1 = {
  beforeImage: {
    imageUrl: renomeFacade11,
  },
  afterImage: {
    imageUrl: renomeFacade12,
  },
};

export const MOCK_SWITCH = [
  {
    number: 50,
    text: `важных\u00A0услуг\u00A0включены в\u00A0ставку\u00A0эксплуатации`,
  },
  {
    number: 110,
    text: 'важных услуг можно\u00A0 заказать\u00A0 дополнительно',
  },
];

export const CIRCLED_PROGRESS_BAR_MOCK = {
  headerText: 'МАКСИМАЛЬНЫЙ НАБОР СЕРВИСОВ',
  contentText:
    'Мы оказываем максимальное количество востребованных услуг: 50 наиболее важных из них включены в ставку эксплуатации и ещё 110 можно заказать дополнительно.',
  footerText:
    'МЫ ГОРДИМСЯ КАЖДЫМ НАШИМ ПРОЕКТОМ И ВНИМАТЕЛЬНО СЛЕДИМ ЗА ТЕМ, ЧТОБЫ ВНЕШНИЙ ОБЛИК ЗДАНИЙ НЕИЗМЕННО СООТВЕТСТВОВАЛ ИЗНАЧАЛЬНОЙ КОНЦЕПЦИИ.',
  imageUrl: sminexDoor,
};

export const PHOTO_FRAME_TEXT_SECTION_MOCK = {
  header: 'ВНИМАНИЕ И ЗАБОТА',
  description:
    'Мы окружаем клиентов вниманием и заботой, демонстрируем уважительное отношение к каждому и практикуем персональный подход. У нас работают вежливые, приветливые сотрудники.',
  secondDescription:
    'Мы не только заботимся о доме в целом, но и — по желанию клиентов — полностью обслуживаем их квартиры и помещения.',
  imgSrc: vnimanieizabota,
};

export const SECTION_WITH_TEXT_ANIMATION = {
  textFirst:
    'Служба комфорта — одно из подразделений Sminex, которое занимается содержанием наших домов, но это больше чем просто управляющая компания. Она работает круглосуточно, обеспечивая максимальный комфорт и премиальное качество жизни для 2 800 человек в 15 наших проектах.',
  header: 'ПРЕМИАЛЬНОЕ КАЧЕСТВО ЖИЗНИ',
  id: 'Внимание и забота',
};
