import React from 'react';
import { FloatBtn } from '@/shared/ui/float-btn/float-btn';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import useIsMobile from '@/shared/hooks/useIsMobile';

export interface AutoplayVideoWithRedirectAndCaptionProps {
  contentForAutoplayVideoBlock: {
    urlVideo: string;
    titleOnVideo: string;
    title: string;
    redirectUrl: string;
    btnName: string;
    text: string;
  };
}
export const AutoplayVideoWithRedirectAndCaption: React.FC<AutoplayVideoWithRedirectAndCaptionProps> = ({
  contentForAutoplayVideoBlock,
}) => {
  const { isMobile } = useIsMobile();

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.videoWrapper)}>
        <video
          {...stylex.props(styles.video)}
          src={contentForAutoplayVideoBlock.urlVideo}
          autoPlay={true}
          controls={false}
          loop={true}
          playsInline
          muted={true}
        ></video>
        {!isMobile && <p {...stylex.props(styles.titleOnVideo)}>{contentForAutoplayVideoBlock.titleOnVideo}</p>}
      </div>

      <div {...stylex.props(styles.buttonWithDescription)}>
        {isMobile && <div {...stylex.props(styles.title)}>{contentForAutoplayVideoBlock.title}</div>}
        <div {...stylex.props(styles.floatBtn)}>
          <FloatBtn
            redirectUrl={contentForAutoplayVideoBlock.redirectUrl}
            btnName={contentForAutoplayVideoBlock.btnName}
          />
        </div>
        <p {...stylex.props(styles.textBlock)}>{contentForAutoplayVideoBlock.text}</p>
      </div>
    </div>
  );
};
