import styles from './styles.module.css';
import { Tabs } from './tabs';
import { PORTFOLIO_MOCK_DATA } from '../api/mock-data';
import { useState } from 'react';
import { Cards } from './cards';
import Maps from './maps';
import { PortfolioItem, PortfolioGroup } from '../model/types';

export const PortfolioPage = () => {
  const result = PORTFOLIO_MOCK_DATA.reduce<Record<string, PortfolioGroup>>((acc, item, index) => {
    const status = item.status;
    if (status !== '') {
      if (!acc[status]) {
        acc[status] = { name: status, count: 0, active: false, items: [] };
      }

      if (index === 0) {
        acc[status] = { name: status, count: 0, active: true, items: [] };
      }

      acc[status].count++;
      acc[status].items?.push(item);

      // Сортируем элементы внутри группы по полю `sort`
      acc[status].items?.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));
    }
    return acc;
  }, {});

  result['Все проекты'] = {
    name: 'Все проекты',
    count: PORTFOLIO_MOCK_DATA.length,
    active: false,
    items: PORTFOLIO_MOCK_DATA,
  };

  const [items, setItems] = useState(result ?? []);
  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);

  const handleTabsClick = (name: PortfolioGroup['name']) => {
    window.scrollTo(0, 0);
    setItems((prevItems) => {
      const updatedItems = { ...prevItems };
      Object.keys(updatedItems).forEach((key) => {
        updatedItems[key].active = updatedItems[key].name === name;
      });
      return updatedItems;
    });
  };

  const getItemsTabs = () => {
    return Object.values(items).map(({ name, count, active }) => ({ name, count, active }));
  };

  const getItemsCards = () => {
    return Object.values(items).find((item) => item?.active)?.items || [];
  };

  const onMouseOverCards = (name: PortfolioItem['name']) => {
    const icons = document.querySelectorAll('[data-placemark-code]');
    icons.forEach((icon) => {
      if (name !== (icon as HTMLElement).dataset.placemarkCode) {
        (icon as HTMLElement).style.opacity = '0';
      }
    });
  };

  const onMouseLeaveCards = () => {
    const icons = document.querySelectorAll('[data-placemark-code]');
    icons.forEach((icon) => {
      (icon as HTMLElement).style.opacity = '1';
    });
  };

  const getItemsMaps = () => {
    const elements = getItemsCards();
    return elements?.map((item) => {
      return { ...item.map, name: item.name, url: item.url, status: item.status };
    });
  };

  const toggleMap = (value: boolean) => {
    setIsMapOpen(value);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.containerTabMobile}>
            <Tabs items={getItemsTabs()} onItemClick={handleTabsClick} />
          </div>
          <div className={[styles.block, isMapOpen ? styles.hidden : ''].join(' ')}>
            <div className={styles.containerTab}>
              <Tabs items={getItemsTabs()} onItemClick={handleTabsClick} />
            </div>
            <div className={styles.containerCards}>
              <Cards items={getItemsCards()} onMouseOver={onMouseOverCards} onMouseLeave={onMouseLeaveCards} />
            </div>
          </div>
          <Maps
            items={getItemsMaps()}
            isMapOpen={isMapOpen}
            toggleMap={toggleMap}
            itemsTabs={getItemsTabs()}
            onItemTabClick={handleTabsClick}
          />
        </div>
      </div>
    </>
  );
};
