import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

const blockIn = stylex.keyframes({
  '0%': {
    transform: 'translateY(100%)',
    clipPath: 'inset(0% 0% 100% 0%)',
  },
  '100%': {
    transform: 'translateY(0)',
    clipPath: 'inset(0% 0% 0% 0%)',
  },
});

export const styles = stylex.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '2.08333rem',
    alignSelf: 'stretch',

    '@media (max-width: 767px)': {
      gap: '8.53333rem',
    },
  },
  videoWrapper: {
    width: '100%',
    position: 'relative',

    '@media (max-width: 767px)': {
      order: '2',
    },
  },
  video: {
    width: '100%',
    height: '40.83333rem',
    objectFit: 'cover',
    display: 'block',

    '@media (max-width: 767px)': {
      height: '68.26667rem',
    },
  },
  titleOnVideo: {
    position: 'absolute',
    top: '28.33333rem',
    left: '11.04167rem',
    width: '49.0625rem',
    color: '#FFF',
    fontFamily: fonts.din2014ExtraLight,
    fontStyle: 'normal',
    fontWeight: '250',
    fontSize: '4.16667rem',
    lineHeight: '4.16667rem',

    animationName: blockIn,
    animationDuration: '1.5s',
    animationFillMode: 'forwards',
  },
  buttonWithDescription: {
    display: 'flex',
    padding: '0rem 11.04167rem 0rem 11.04167rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',

    '@media (max-width: 767px)': {
      flexDirection: 'column',
      padding: '0rem 4rem 0rem 4.26667rem',
      justifyContent: 'flex-start',
      gap: '8.53333rem',
      order: '1',
    },
  },
  title: {
    '@media (max-width: 767px)': {
      width: '100%',
      color: colors.primaryText,
      fontFamily: fonts.din2014ExtraLight,
      fontStyle: 'normal',
      fontWeight: '250',
      alignSelf: 'stretch',
      fontSize: '10.66667rem',
      lineHeight: '10.66667rem',

      animationName: blockIn,
      animationDuration: '1s',
      animationFillMode: 'forwards',
    },
  },
  floatBtn: {
    '@media (max-width: 767px)': {
      order: '2',
    },
  },
  textBlock: {
    width: '30.41667rem',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      width: '100%',
      alignSelf: 'stretch',
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
      order: '1',

      animationName: blockIn,
      animationDuration: '1s',
      animationFillMode: 'forwards',
    },
  },
});
