export const AppStore = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="152" height="50" viewBox="0 0 152 50" fill="none">
      <g clipPath="url(#clip0_10224_9442)">
        <path
          d="M139.475 0.0001625H12.5315C12.0688 0.0001625 11.6116 0.0001625 11.1501 0.0026625C10.7638 0.0051625 10.3805 0.012425 9.99049 0.0185375C9.14317 0.0284148 8.29783 0.102258 7.46184 0.239425C6.62702 0.379578 5.81835 0.643807 5.06318 1.02318C4.30894 1.40575 3.61977 1.90287 3.02067 2.4965C2.41842 3.08846 1.9164 3.77268 1.53391 4.52286C1.15037 5.27155 0.884415 6.07384 0.745244 6.90201C0.604749 7.72914 0.529147 8.56581 0.519117 9.40451C0.507407 9.78776 0.506171 10.1723 0.5 10.5556V39.4484C0.506171 39.8365 0.507407 40.2125 0.519117 40.6008C0.52915 41.4394 0.604752 42.2761 0.745244 43.1032C0.884031 43.9318 1.15 44.7346 1.53391 45.4835C1.91622 46.2313 2.41831 46.9127 3.02067 47.5014C3.6175 48.0977 4.30712 48.5951 5.06318 48.9748C5.81835 49.3552 6.62693 49.621 7.46184 49.7633C8.29798 49.8994 9.14322 49.9732 9.99049 49.9843C10.3805 49.9928 10.7638 49.9977 11.1501 49.9977C11.6116 50.0002 12.0688 50.0002 12.5315 50.0002H139.475C139.929 50.0002 140.39 50.0002 140.843 49.9977C141.228 49.9977 141.622 49.9928 142.006 49.9843C142.852 49.9738 143.696 49.8999 144.53 49.7633C145.368 49.62 146.179 49.3542 146.938 48.9748C147.693 48.5949 148.382 48.0975 148.979 47.5014C149.58 46.9104 150.083 46.2295 150.47 45.4835C150.851 44.734 151.114 43.9313 151.251 43.1032C151.392 42.276 151.47 41.4395 151.485 40.6008C151.49 40.2125 151.49 39.8365 151.49 39.4484C151.5 38.9943 151.5 38.5427 151.5 38.0812V11.9203C151.5 11.4626 151.5 11.0085 151.49 10.5556C151.49 10.1723 151.49 9.78776 151.485 9.40446C151.47 8.56569 151.392 7.72921 151.251 6.90196C151.114 6.07426 150.85 5.27204 150.47 4.52281C149.691 3.01916 148.456 1.79519 146.938 1.02306C146.179 0.644622 145.368 0.380463 144.53 0.239312C143.696 0.101541 142.852 0.0276722 142.006 0.0183625C141.622 0.0122625 141.228 0.0049375 140.843 0.0025C140.39 0 139.929 0.0001625 139.475 0.0001625Z"
          fill="#A6A6A6"
        />
        <path
          d="M11.1562 48.9062C10.7717 48.9062 10.3965 48.9014 10.0151 48.8929C9.22489 48.8827 8.43653 48.8145 7.65649 48.689C6.92914 48.5649 6.22453 48.3341 5.5659 48.0041C4.91332 47.6769 4.31811 47.2478 3.80308 46.7334C3.28059 46.225 2.84559 45.6353 2.51533 44.9877C2.18143 44.336 1.95035 43.6374 1.83014 42.9162C1.70032 42.1414 1.63009 41.3579 1.62004 40.5725C1.61204 40.3089 1.60156 39.4311 1.60156 39.4311V10.5554C1.60156 10.5554 1.61272 9.69116 1.6201 9.43725C1.62972 8.6531 1.69955 7.87082 1.82898 7.09716C1.94941 6.37406 2.18067 5.67344 2.51475 5.01954C2.8438 4.37243 3.27638 3.78233 3.79572 3.2721C4.31448 2.75703 4.91159 2.32576 5.56529 1.99401C6.2224 1.66512 6.92567 1.43592 7.65155 1.31409C8.43416 1.1873 9.22525 1.11875 10.0182 1.10901L11.1568 1.09375H140.837L141.989 1.10963C142.775 1.11887 143.559 1.18681 144.334 1.31286C145.067 1.43622 145.778 1.66702 146.443 1.99767C147.752 2.66623 148.818 3.72395 149.49 5.02259C149.819 5.67197 150.046 6.36688 150.165 7.08374C150.296 7.86374 150.37 8.65217 150.385 9.44275C150.388 9.79675 150.388 10.177 150.388 10.5554C150.398 11.0242 150.398 11.4703 150.398 11.9202V38.081C150.398 38.5351 150.398 38.9782 150.388 39.425C150.388 39.8315 150.388 40.2039 150.383 40.5871C150.369 41.3636 150.297 42.138 150.168 42.904C150.05 43.6303 149.82 44.3344 149.486 44.9915C149.154 45.632 148.721 46.2166 148.205 46.7236C147.689 47.2408 147.093 47.6724 146.439 48.0017C145.776 48.3342 145.067 48.5659 144.334 48.689C143.554 48.8152 142.766 48.8833 141.976 48.8929C141.606 48.9014 141.219 48.9062 140.843 48.9062L139.475 48.9087L11.1562 48.9062Z"
          fill="#1D252D"
        />
        <path
          d="M31.7543 25.3759C31.7678 24.3326 32.0476 23.3096 32.5675 22.4022C33.0874 21.4947 33.8306 20.7323 34.7279 20.1858C34.1579 19.3794 33.4059 18.7157 32.5316 18.2475C31.6574 17.7793 30.685 17.5195 29.6916 17.4887C27.5726 17.2684 25.5184 18.7448 24.4386 18.7448C23.3379 18.7448 21.6754 17.5106 19.8851 17.5471C18.7272 17.5841 17.5986 17.9177 16.6095 18.5153C15.6203 19.1129 14.8043 19.9541 14.2409 20.9569C11.8005 25.1424 13.6208 31.2938 15.9586 34.677C17.1282 36.3337 18.4951 38.1843 20.2838 38.1186C22.0341 38.0467 22.6878 37.013 24.8005 37.013C26.8936 37.013 27.5069 38.1186 29.3319 38.0769C31.21 38.0467 32.3933 36.4129 33.5219 34.7405C34.3623 33.56 35.0089 32.2554 35.4379 30.8749C34.3468 30.4177 33.4156 29.6525 32.7606 28.6746C32.1055 27.6967 31.7555 26.5495 31.7543 25.3759Z"
          fill="white"
        />
        <path
          d="M28.3077 15.2637C29.3317 14.0459 29.8362 12.4807 29.714 10.9004C28.1495 11.0632 26.7044 11.8039 25.6665 12.9749C25.159 13.547 24.7704 14.2125 24.5228 14.9335C24.2751 15.6545 24.1734 16.4167 24.2233 17.1766C25.0058 17.1846 25.78 17.0166 26.4875 16.6852C27.1949 16.3539 27.8173 15.8678 28.3077 15.2637Z"
          fill="white"
        />
        <path
          d="M53.8786 33.9246H47.9057L46.4713 38.1202H43.9414L49.5989 22.5977H52.2273L57.8848 38.1202H55.3117L53.8786 33.9246ZM48.5243 31.9886H53.2588L50.9249 25.1795H50.8595L48.5243 31.9886Z"
          fill="white"
        />
        <path
          d="M70.1034 32.4622C70.1034 35.979 68.2032 38.2385 65.3357 38.2385C64.6093 38.2762 63.8869 38.1104 63.2512 37.7603C62.6154 37.4101 62.0921 36.8897 61.7411 36.2585H61.6868V41.864H59.3418V26.803H61.6117V28.6853H61.6548C62.022 28.0572 62.5542 27.5395 63.1947 27.1872C63.8353 26.8349 64.5603 26.6612 65.2926 26.6846C68.1921 26.6846 70.1034 28.9551 70.1034 32.4622ZM67.6931 32.4622C67.6931 30.1709 66.4977 28.6646 64.6739 28.6646C62.8822 28.6646 61.677 30.2027 61.677 32.4622C61.677 34.7425 62.8822 36.2696 64.6739 36.2696C66.4977 36.2696 67.6931 34.7742 67.6931 32.4622Z"
          fill="white"
        />
        <path
          d="M82.6776 32.4622C82.6776 35.979 80.7775 38.2385 77.9099 38.2385C77.1835 38.2762 76.4611 38.1104 75.8254 37.7602C75.1897 37.4101 74.6663 36.8896 74.3153 36.2585H74.2611V41.864H71.916V26.803H74.1859V28.6853H74.229C74.5962 28.0572 75.1283 27.5395 75.7689 27.1872C76.4095 26.8349 77.1345 26.6612 77.8667 26.6846C80.7664 26.6846 82.6776 28.9551 82.6776 32.4622ZM80.2673 32.4622C80.2673 30.1709 79.072 28.6646 77.2482 28.6646C75.4564 28.6646 74.2512 30.2026 74.2512 32.4622C74.2512 34.7424 75.4564 36.2695 77.2482 36.2695C79.072 36.2695 80.2673 34.7742 80.2673 32.4622Z"
          fill="white"
        />
        <path
          d="M90.9884 33.7952C91.1622 35.3345 92.6718 36.3452 94.7346 36.3452C96.7112 36.3452 98.1333 35.3345 98.1333 33.9465C98.1333 32.7417 97.2756 32.0203 95.2448 31.5259L93.214 31.0413C90.3366 30.3528 89.0007 29.0198 89.0007 26.8567C89.0007 24.1785 91.3569 22.3389 94.7026 22.3389C98.0137 22.3389 100.284 24.1785 100.36 26.8567H97.9927C97.851 25.3076 96.5584 24.3726 94.6693 24.3726C92.7802 24.3726 91.4875 25.3186 91.4875 26.6956C91.4875 27.793 92.3131 28.4387 94.3328 28.9331L96.0593 29.353C99.2743 30.1062 100.61 31.3855 100.61 33.656C100.61 36.56 98.2749 38.3789 94.5608 38.3789C91.0857 38.3789 88.7395 36.6027 88.5879 33.7951L90.9884 33.7952Z"
          fill="white"
        />
        <path
          d="M105.67 24.1249V26.8031H107.842V28.6427H105.67V34.8817C105.67 35.851 106.105 36.3026 107.06 36.3026C107.318 36.2982 107.575 36.2802 107.831 36.2489V38.0775C107.402 38.157 106.965 38.1929 106.529 38.1849C104.216 38.1849 103.313 37.3243 103.313 35.1295V28.6427H101.652V26.8031H103.313V24.1249H105.67Z"
          fill="white"
        />
        <path
          d="M109.102 32.4623C109.102 28.9015 111.219 26.6639 114.52 26.6639C117.832 26.6639 119.94 28.9014 119.94 32.4623C119.94 36.0328 117.843 38.2606 114.52 38.2606C111.198 38.2606 109.102 36.0328 109.102 32.4623ZM117.55 32.4623C117.55 30.0197 116.42 28.578 114.52 28.578C112.62 28.578 111.491 30.0306 111.491 32.4623C111.491 34.9147 112.62 36.3453 114.52 36.3453C116.42 36.3453 117.55 34.9147 117.55 32.4623Z"
          fill="white"
        />
        <path
          d="M121.873 26.803H124.11V28.7293H124.164C124.315 28.1277 124.671 27.596 125.171 27.2236C125.672 26.8513 126.287 26.6609 126.912 26.6846C127.182 26.6836 127.452 26.7127 127.715 26.7713V28.9441C127.374 28.8408 127.018 28.7934 126.662 28.8037C126.321 28.79 125.981 28.8495 125.666 28.9782C125.351 29.1068 125.067 29.3014 124.835 29.5488C124.603 29.7962 124.428 30.0904 124.321 30.4112C124.215 30.732 124.18 31.0719 124.218 31.4075V38.1201H121.873L121.873 26.803Z"
          fill="white"
        />
        <path
          d="M138.526 34.7963C138.211 36.8507 136.191 38.2606 133.607 38.2606C130.284 38.2606 128.221 36.0548 128.221 32.516C128.221 28.9662 130.295 26.6639 133.508 26.6639C136.669 26.6639 138.657 28.8148 138.657 32.2462V33.0421H130.588V33.1825C130.551 33.599 130.603 34.0186 130.742 34.4134C130.881 34.8082 131.104 35.1692 131.394 35.4723C131.685 35.7755 132.037 36.014 132.428 36.1719C132.819 36.3297 133.239 36.4034 133.661 36.3881C134.215 36.4395 134.772 36.3124 135.247 36.0255C135.722 35.7387 136.091 35.3076 136.3 34.7963L138.526 34.7963ZM130.599 31.4186H136.311C136.332 31.0441 136.274 30.6693 136.142 30.3178C136.01 29.9663 135.806 29.6456 135.543 29.3759C135.28 29.1062 134.963 28.8933 134.613 28.7505C134.263 28.6077 133.887 28.5382 133.508 28.5463C133.127 28.544 132.748 28.6167 132.395 28.7601C132.041 28.9035 131.72 29.1149 131.45 29.3819C131.179 29.649 130.965 29.9664 130.819 30.316C130.673 30.6656 130.598 31.0403 130.599 31.4186Z"
          fill="white"
        />
        <path
          d="M46.859 14.0667H47.8658C48.0391 14.0881 48.215 14.0737 48.3824 14.0244C48.5498 13.9751 48.7051 13.892 48.8385 13.7803C48.9718 13.6686 49.0804 13.5307 49.1573 13.3754C49.2342 13.22 49.2779 13.0506 49.2854 12.8778C49.2854 12.1899 48.7629 11.7456 47.8769 11.7456C47.6993 11.721 47.5186 11.7319 47.3454 11.7777C47.1721 11.8234 47.0099 11.9031 46.8683 12.012C46.7266 12.1209 46.6085 12.2568 46.5207 12.4117C46.433 12.5666 46.3775 12.7373 46.3575 12.9138H45.2521C45.2711 12.5934 45.3565 12.2803 45.503 11.9941C45.6494 11.7078 45.8538 11.4545 46.1034 11.2499C46.3529 11.0452 46.6424 10.8937 46.9536 10.8047C47.2649 10.7156 47.5913 10.691 47.9126 10.7324C49.395 10.7324 50.4388 11.5545 50.4388 12.738C50.4423 13.1345 50.3084 13.5201 50.0595 13.8306C49.8105 14.1411 49.4616 14.3576 49.071 14.4439V14.5318C49.5341 14.5522 49.9702 14.7533 50.284 15.0913C50.5979 15.4293 50.7638 15.8766 50.7457 16.3354C50.7114 16.6653 50.611 16.985 50.4504 17.276C50.2897 17.5669 50.0721 17.8231 49.8101 18.0297C49.5482 18.2362 49.2473 18.3888 48.9251 18.4787C48.6029 18.5685 48.2658 18.5937 47.9336 18.5528C47.6007 18.5978 47.2621 18.5759 46.9379 18.4884C46.6137 18.401 46.3106 18.2498 46.0467 18.0438C45.7828 17.8379 45.5635 17.5814 45.4017 17.2896C45.24 16.9979 45.1393 16.6769 45.1055 16.3458H46.2281C46.2959 17.0593 46.9576 17.5195 47.9336 17.5195C48.9095 17.5195 49.5466 17.0026 49.5466 16.2738C49.5466 15.5347 48.9465 15.0794 47.8978 15.0794H46.859V14.0667Z"
          fill="white"
        />
        <path
          d="M52.0215 16.7804C52.0215 15.7672 52.7843 15.1831 54.1349 15.1001L55.674 15.0122V14.5264C55.674 13.9319 55.2784 13.5962 54.5107 13.5962C53.8847 13.5962 53.4522 13.8239 53.3265 14.2218H52.2408C52.3554 13.255 53.2747 12.6349 54.5637 12.6349C55.9882 12.6349 56.7917 13.3374 56.7917 14.5264V18.3722H55.711V17.5812H55.6222C55.4422 17.8649 55.1893 18.0962 54.8894 18.2515C54.5896 18.4068 54.2535 18.4804 53.9155 18.4649C53.677 18.4895 53.4359 18.4643 53.2078 18.3909C52.9797 18.3175 52.7697 18.1976 52.5912 18.0389C52.4128 17.8802 52.2699 17.6862 52.1717 17.4695C52.0736 17.2527 52.0224 17.018 52.0215 16.7804ZM55.674 16.2994V15.8289L54.2864 15.9167C53.5039 15.9686 53.149 16.2323 53.149 16.7285C53.149 17.2351 53.5926 17.5299 54.2026 17.5299C54.3814 17.5478 54.5619 17.5299 54.7336 17.4773C54.9052 17.4247 55.0644 17.3385 55.2016 17.2237C55.3389 17.1089 55.4515 16.9679 55.5326 16.8091C55.6138 16.6503 55.6619 16.477 55.674 16.2994Z"
          fill="white"
        />
        <path d="M59.7679 13.6786V18.3722H58.6465V12.7435H62.3878V13.6785L59.7679 13.6786Z" fill="white" />
        <path
          d="M69.1832 15.5554C69.1832 17.3437 68.2701 18.4649 66.8357 18.4649C66.4836 18.4887 66.1319 18.4124 65.8221 18.2449C65.5123 18.0774 65.2573 17.8257 65.0871 17.5195H64.9983V20.2435H63.877V12.7435H64.9564V13.6737H65.0402C65.2135 13.3567 65.4719 13.0934 65.7868 12.9128C66.1017 12.7322 66.4608 12.6413 66.8246 12.6501C68.2701 12.6501 69.1832 13.7664 69.1832 15.5554ZM68.0298 15.5554C68.0298 14.3664 67.4617 13.648 66.5116 13.648C65.5677 13.648 64.9774 14.3817 64.9774 15.5603C64.9774 16.7334 65.5677 17.4676 66.5067 17.4676C67.4617 17.4676 68.0298 16.7492 68.0298 15.5554Z"
          fill="white"
        />
        <path
          d="M70.6769 20.3882V19.4318C70.7982 19.447 70.9205 19.454 71.0428 19.4525C71.2817 19.47 71.5188 19.3996 71.7084 19.2546C71.8981 19.1096 72.0271 18.9004 72.0705 18.667L72.1543 18.3929L70.0977 12.7435H71.3189L72.7434 17.2144H72.8322L74.2518 12.7435H75.4409L73.3226 18.6047C72.8371 19.9744 72.2998 20.4138 71.1415 20.4138C71.0478 20.4138 70.7816 20.4034 70.6769 20.3882Z"
          fill="white"
        />
        <path
          d="M76.3145 16.7749H77.4309C77.5406 17.2712 78.0101 17.5299 78.6669 17.5299C79.4137 17.5299 79.8721 17.204 79.8721 16.6974C79.8721 16.1548 79.4605 15.896 78.6669 15.896H77.8166V15.033H78.6521C79.3299 15.033 79.6749 14.7748 79.6749 14.3042C79.6749 13.891 79.341 13.5755 78.6521 13.5755C78.0249 13.5755 77.5665 13.8392 77.4937 14.2835H76.413C76.4938 13.7729 76.7737 13.3142 77.1924 13.0059C77.6111 12.6976 78.1353 12.5644 78.6521 12.6349C80.0027 12.6349 80.7754 13.2398 80.7754 14.1907C80.7817 14.4555 80.696 14.7144 80.5327 14.9242C80.3694 15.134 80.1384 15.2818 79.8782 15.3431V15.431C80.188 15.4705 80.4722 15.6214 80.6771 15.8548C80.882 16.0883 80.9933 16.3881 80.9898 16.6974C80.9898 17.7674 80.0705 18.4754 78.6669 18.4754C77.426 18.4753 76.418 17.912 76.3145 16.7749Z"
          fill="white"
        />
        <path
          d="M82.6055 18.3722V12.7435H83.717V16.615H83.8057L86.4243 12.7435H87.5359V18.3722H86.4243V14.48H86.3405L83.717 18.3722L82.6055 18.3722Z"
          fill="white"
        />
        <path d="M93.6583 13.6785H91.869V18.3721H90.7464V13.6785H88.957V12.7435H93.6583V13.6785Z" fill="white" />
        <path
          d="M99.6983 16.8524C99.5455 17.3668 99.2151 17.8117 98.7649 18.1093C98.3146 18.407 97.7731 18.5384 97.235 18.4808C96.8606 18.4906 96.4886 18.4196 96.1447 18.2727C95.8009 18.1258 95.4934 17.9065 95.2437 17.6301C94.9939 17.3536 94.8079 17.0267 94.6985 16.6719C94.5891 16.3171 94.559 15.943 94.6102 15.5755C94.5603 15.2069 94.591 14.832 94.7001 14.4762C94.8093 14.1203 94.9943 13.7919 95.2427 13.513C95.4912 13.2342 95.7971 13.0115 96.14 12.86C96.4829 12.7085 96.8546 12.6317 97.23 12.6349C98.8111 12.6349 99.7661 13.7049 99.7661 15.4724V15.86H95.7537V15.9222C95.736 16.1288 95.762 16.3368 95.8303 16.5329C95.8985 16.7289 96.0073 16.9086 96.1499 17.0605C96.2924 17.2123 96.4654 17.333 96.6578 17.4147C96.8502 17.4963 97.0577 17.5372 97.267 17.5347C97.5351 17.5665 97.8066 17.5186 98.0471 17.3971C98.2876 17.2756 98.4862 17.086 98.6176 16.8524L99.6983 16.8524ZM95.7537 15.0384H98.6225C98.6366 14.8496 98.6108 14.66 98.5466 14.4816C98.4824 14.3033 98.3812 14.1402 98.2496 14.0029C98.118 13.8656 97.9589 13.7571 97.7825 13.6843C97.606 13.6115 97.4162 13.5761 97.2251 13.5803C97.0311 13.5777 96.8385 13.6136 96.6588 13.686C96.479 13.7583 96.3157 13.8657 96.1786 14.0016C96.0414 14.1375 95.9332 14.2993 95.8602 14.4774C95.7872 14.6555 95.751 14.8463 95.7537 15.0384Z"
          fill="white"
        />
        <path
          d="M104.453 12.7435H107.015C108.127 12.7435 108.778 13.3014 108.778 14.1748C108.78 14.4519 108.686 14.7212 108.511 14.9377C108.336 15.1541 108.092 15.3045 107.819 15.3637V15.4516C108.15 15.4724 108.461 15.6186 108.688 15.8601C108.914 16.1016 109.038 16.4199 109.034 16.7492C109.034 17.7313 108.288 18.3722 107.041 18.3722H104.453V12.7435ZM105.565 13.5962V15.0537H106.676C107.354 15.0537 107.678 14.7955 107.678 14.3194C107.678 13.8806 107.396 13.5962 106.801 13.5962L105.565 13.5962ZM105.565 15.8704V17.5195H106.894C107.557 17.5195 107.912 17.2296 107.912 16.6974C107.912 16.1341 107.51 15.8704 106.754 15.8704L105.565 15.8704Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10224_9442">
          <rect width="151" height="50" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
