import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    width: '30.41667rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2.91667rem',

    '@media (max-width: 767px)': {
      width: '100%',
      gap: '8.53333rem',
    },
  },
  statisticsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',

    '@media (max-width: 767px)': {
      justifyContent: 'flex-start',
      gap: '5.2rem',
    },
  },
  separator: {
    color: colors.secondaryBorder,
    width: '0.06208rem',
    height: '7.91667rem',

    '@media (max-width: 767px)': {
      width: '0.26667rem',
      height: '20.53333rem',
    },
  },
  squareContainer: {
    display: 'flex',
    padding: '0.41667rem 0rem 0.41667rem 0rem',
    flexDirection: 'column',
    alignItems: 'center',

    '@media (max-width: 767px)': {
      padding: '2.13333rem 0rem 2.13333rem 0rem',
      width: '40.53333rem',
    },
  },
  squareBlock: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.41667rem',
    alignSelf: 'stretch',

    '@media (max-width: 767px)': {
      gap: '2.13333rem',
    },
  },
  numericalIndicator: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '3.33333rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '4.16667rem',

    '@media (max-width: 767px)': {
      fontSize: '9.6rem',
      lineHeight: '10.66667rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  unitOfMeasurement: {
    color: colors.secondaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.72917rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.04167rem',

    '@media (max-width: 767px)': {
      fontSize: '3.73333rem',
      lineHeight: '5.33333rem',
    },
  },
  textInSquareContainer: {
    alignSelf: 'stretch',
    color: colors.secondaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.72917rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.04167rem',

    '@media (max-width: 767px)': {
      fontSize: '3.73333rem',
      lineHeight: '5.33333rem',
    },
  },
  textAndButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.91667rem',

    '@media (max-width: 767px)': {
      gap: '8.53333rem',
    },
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.83333rem',

    '@media (max-width: 767px)': {
      gap: '4.26667rem',
    },
  },
  text: {
    alignSelf: 'stretch',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
});
