import React, { useEffect, useRef, useState } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ItemTypes } from '../model/types';
import useIsMobile from '@/shared/hooks/useIsMobile';

interface DropdownWithImagesProps {
  dropdownItems: ItemTypes[];
}

export const DropdownWithImages: React.FC<DropdownWithImagesProps> = ({ dropdownItems }) => {
  const [currentId, setCurrentId] = useState<number>(1);
  const [openAccordionId, setOpenAccordionId] = useState<number | null>(1);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const descriptionBlockRef = useRef<HTMLDivElement | null>(null);

  const { isMobile } = useIsMobile();

  const toggleIsOpen = (itemId: number) => {
    if (openAccordionId === itemId) {
      setOpenAccordionId(null);
      setContentHeight(0);
      return;
    }
    setOpenAccordionId(itemId);
    setCurrentId(itemId);
  };

  useEffect(() => {
    const updateContentHeight = () => {
      if (descriptionBlockRef.current) {
        const rootFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
        const heightInRems = descriptionBlockRef.current.scrollHeight / rootFontSize;
        setContentHeight(heightInRems);
      }
    };

    const img = descriptionBlockRef.current?.querySelector('img');
    img
      ? img.complete
        ? updateContentHeight()
        : img.addEventListener('load', updateContentHeight)
      : updateContentHeight();

    return () => img?.removeEventListener('load', updateContentHeight);
  }, [openAccordionId]);

  return (
    <div {...stylex.props(styles.container)}>
      {!isMobile && (
        <div {...stylex.props(styles.imageBlock)}>
          {dropdownItems.map((item) => (
            <img
              src={item.imageSrc}
              alt={item.altSrc}
              key={item.id}
              {...stylex.props(styles.img(item.id === currentId))}
              draggable={false}
            />
          ))}
        </div>
      )}

      <div {...stylex.props(styles.dropdownsContainer)}>
        {dropdownItems.map((item) => (
          <div key={item.id} {...stylex.props(styles.itemDropdownBlock)}>
            <div
              {...stylex.props(styles.titleBlock(item.id === openAccordionId))}
              onClick={() => toggleIsOpen(item.id)}
            >
              <p {...stylex.props(styles.title)}>{item.title}</p>
              <span {...stylex.props(styles.plusIcon(item.id === openAccordionId))}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                  <path d="M12 21L12 3M21 12L12 12L3 12" stroke="currentColor" />
                </svg>
              </span>
            </div>

            <div
              {...stylex.props(styles.descriptionBlock(contentHeight, item.id === openAccordionId))}
              ref={item.id === openAccordionId ? descriptionBlockRef : null}
            >
              <div {...stylex.props(styles.paragraphsBlock)}>
                <p {...stylex.props(styles.description)}>{item.firstDescription}</p>
                {item.secondDescription && <p {...stylex.props(styles.description)}>{item.secondDescription}</p>}
              </div>

              {isMobile && (
                <img
                  {...stylex.props(styles.img(item.id === currentId))}
                  src={item.imageSrc}
                  alt={item.altSrc}
                  draggable={false}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
