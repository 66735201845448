import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { FloatBtn } from '@/shared/ui/float-btn/float-btn';

interface TextAndButtonProps {
  firstDescription?: string;
  secondDescription?: string;
  btnName?: string;
  redirectUrl?: string;
}

export const TextAndButton: React.FC<TextAndButtonProps> = ({
  firstDescription,
  secondDescription,
  btnName,
  redirectUrl,
}) => {
  return (
    <div {...stylex.props(styles.textAndButtonContainer)}>
      {firstDescription && (
        <div {...stylex.props(styles.textBlock)}>
          <p {...stylex.props(styles.text)}>{firstDescription}</p>
          {secondDescription && <p {...stylex.props(styles.text)}>{secondDescription}</p>}
        </div>
      )}
      {btnName && redirectUrl && <FloatBtn redirectUrl={redirectUrl} btnName={btnName} />}
    </div>
  );
};
