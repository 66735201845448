import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: (heightContainer, mobileHeightContainer) => ({
    width: '100%',
    height: heightContainer ? heightContainer : '',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'stretch',
    padding: '0rem 11.04167rem 0rem 11.04167rem',

    '@media (max-width: 767px)': {
      height: mobileHeightContainer ? mobileHeightContainer : '169.86667rem',
      padding: '0rem 0rem 0rem 0rem',
      overflow: 'hidden',
    },
  }),
  circleBlock: {
    width: '46.875rem',
    height: '46.875rem',
    flexShrink: '0',
    position: 'relative',
    overflow: 'visible',

    '@media (max-width: 767px)': {
      width: '122.13333rem',
      height: '122.13333rem',
      flexShrink: '1',
    },
  },
  middleTextBlock: {
    position: 'absolute',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.625rem',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',

    '@media (max-width: 767px)': {
      gap: '0rem',
    },
  },
  mainMiddleText: {
    color: colors.primaryText,
    textAlign: 'center',
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '6.66667rem',
    lineHeight: '6.66667rem',

    '@media (max-width: 767px)': {
      fontSize: '25.6rem',
      lineHeight: '25.6rem',
    },
  },
  secondaryMiddleText: {
    color: colors.secondaryText,
    textAlign: 'center',
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '1.04167rem',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
  topTextBlock: (widthTopText) => ({
    position: 'absolute',
    width: widthTopText ? widthTopText : '25rem',
    top: '9.6875rem',
    left: '-15.52083rem',

    '@media (max-width: 767px)': {
      width: '100%',
      top: '0rem',
      left: '0rem',
      padding: '6.4rem 4.26667rem 6.4rem 4.26667rem',
    },
  }),
  topText: {
    alignSelf: 'stretch',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '3.125rem',
    lineHeight: '4.16667rem',

    '@media (max-width: 767px)': {
      fontSize: '8rem',
      lineHeight: '10.66667rem',
    },
  },
  imgBlock: {
    position: 'absolute',
    top: '24.27083rem',
    left: '-15.52083rem',
    width: '22.55208rem',
    height: '19.6875rem',
    borderRadius: '2.91667rem 0rem 0rem 0rem',
    objectFit: 'cover',

    '@media (max-width: 767px)': {
      position: 'static',
      top: '0rem',
      left: '0rem',
      width: '43.46667rem',
      height: '37.06667rem',
      borderRadius: '7.2rem 0rem 0rem 0rem',
    },
  },
  bottomTextBlock: {
    position: 'absolute',
    top: '37.08333rem',
    right: '-15.52083rem',

    '@media (max-width: 767px)': {
      position: 'static',
      top: '0rem',
      right: '0rem',
    },
  },
  mobImageAndTextBlock: {
    '@media (max-width: 767px)': {
      position: 'absolute',
      top: '98.66667rem',
      display: 'flex',
      flexDirection: 'column',
      padding: '0rem 4.26667rem 0rem 4.26667rem',
      width: '100%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      gap: '8.53333rem',
    },
  },
  svgMob: {
    '@media (max-width: 767px)': {
      transform: 'rotate(-180deg)',
    },
  },
});
