import ordynkaLogo from '@/shared/ui/assets/reality-page/ordynka-logo.svg';
import renomeLogo from '@/shared/ui/assets/reality-page/renome-logo.svg';
import JazzLogo from '@/shared/ui/assets/reality-page/jazz-logo.svg';
import ordynkaFacade11 from '@/shared/ui/assets/reality-page/ordynka-facade-1-1.jpg';
import ordynkaFacade12 from '@/shared/ui/assets/reality-page/ordynka-facade-1-2.jpg';
import ordynkaDvor11 from '@/shared/ui/assets/reality-page/ordynka-dvor-1-1.jpg';
import ordynkaDvor12 from '@/shared/ui/assets/reality-page/ordynka-dvor-1-2.jpg';
import ordynkaDvor21 from '@/shared/ui/assets/reality-page/ordynka-dvor-2-1.jpg';
import ordynkaDvor22 from '@/shared/ui/assets/reality-page/ordynka-dvor-2-2.jpg';
import ordynkaFacade21 from '@/shared/ui/assets/reality-page/ordynka-facade-2-1.jpg';
import ordynkaFacade22 from '@/shared/ui/assets/reality-page/ordynka-facade-2-2.jpg';
import ordynkaLobby1 from '@/shared/ui/assets/reality-page/ordynka-lobby-1.jpg';
import ordynkaLobby2 from '@/shared/ui/assets/reality-page/ordynka-lobby-2.jpg';
import renomeFacade11 from '@/shared/ui/assets/reality-page/renome-facade-1-1.jpg';
import renomeFacade12 from '@/shared/ui/assets/reality-page/renome-facade-1-2.jpg';
import renomeDvor11 from '@/shared/ui/assets/reality-page/renome-dvor-1-1.jpg';
import renomeDvor12 from '@/shared/ui/assets/reality-page/renome-dvor-1-2.jpg';
import renomeDvor21 from '@/shared/ui/assets/reality-page/renome-dvor-2-1.jpg';
import renomeDvor22 from '@/shared/ui/assets/reality-page/renome-dvor-2-2.jpg';
import renomeLobby11 from '@/shared/ui/assets/reality-page/renome-lobby-1-1.jpg';
import renomeLobby12 from '@/shared/ui/assets/reality-page/renome-lobby-1-2.jpg';
import renomeLobby21 from '@/shared/ui/assets/reality-page/renome-lobby-2-1.jpg';
import renomeLobby22 from '@/shared/ui/assets/reality-page/renome-lobby-2-2.jpg';
import dvorRenome1 from '@shared/ui/assets/main-page/1-renome-dvor.jpg';
import dvorRenome2 from '@shared/ui/assets/main-page/2-renome-dvor.jpg';
import dvorRenome3 from '@shared/ui/assets/main-page/3-renome-dvor.jpg';
import dvorRenome4 from '@shared/ui/assets/main-page/4-renome-dvor.jpg';
import renome5 from '@shared/ui/assets/main-page/5-renome.jpg';
import ordynka1 from '@shared/ui/assets/main-page/6-ordynka.jpg';
import ordynka2 from '@shared/ui/assets/main-page/7-ordynka.jpg';
import ordynka3 from '@shared/ui/assets/main-page/8-ordynka.jpg';
import ordynka4 from '@shared/ui/assets/main-page/9-ordynka.jpg';
import ordynka5 from '@shared/ui/assets/main-page/10-ordynka.jpg';
import jazz from '@shared/ui/assets/main-page/11-jazz.jpg';
import enter from '@shared/ui/assets/main-page/enter.png';
import kamin from '@shared/ui/assets/main-page/kamin.png';
import houseFromVeranda from '@shared/ui/assets/main-page/house-from-veranda.png';
import receptionOrdinka from '@shared/ui/assets/main-page/reception-malaya-ordinka-19.png';
import mainPageVideo from '@shared/ui/videos/main-page-video.mp4';

import fitLabLavr from '@shared/ui/assets/main-page/slider-half-screen/fit-lab-lavr.png';
import friendsLabDostizhenie from '@shared/ui/assets/main-page/slider-half-screen/friends-lab-dostizhenie.png';
import kaminChasy from '@shared/ui/assets/main-page/slider-half-screen/kamin-chasy.png';
import kidsLabOrdinka from '@shared/ui/assets/main-page/slider-half-screen/kids-lab-ordinka.png';
import lobbiChistiePrydi from '@shared/ui/assets/main-page/slider-half-screen/lobbi-chistie-prydi.png';
import parkingLavr from '@shared/ui/assets/main-page/slider-half-screen/parking-lavr.png';
import pentkhausKaminomChistie from '@shared/ui/assets/main-page/slider-half-screen/pentkhaus-s-kaminom-chistie.png';

import girlOnReception from '@shared/ui/assets/main-page/dropdown/girl-on-reception.png';
import girlWithCouple from '@shared/ui/assets/main-page/dropdown/girl-with-couple.png';
import phone from '@shared/ui/assets/main-page/dropdown/phone.png';

import { TextBlockWithRedirectButtonType } from '@/pages/main-page/ui/text-block-with-redirect-button/model/types';
import { ImageType } from '@/pages/main-page/ui/text-block-and-image-with-caption/model/types';
import { ContentType } from '@/pages/main-page/ui/circle-progress-bar-main-page/model/types';
import { SlideType } from '@/features/slider-half-screen/model/types';
import { ItemTypes } from '@/pages/main-page/ui/dropdown-with-images/model/types';

export const contentForAutoplayVideoBlock = {
  urlVideo: mainPageVideo,
  titleOnVideo: 'ПЕРФЕКЦИОНИСТЫ FINE\u00A0DEVELOPMENT',
  title: `ПЕРФЕКЦИО-НИСТЫ FINE\u00A0DEVELOPMENT`,
  redirectUrl: '/portfolio-proyectov',
  btnName: 'ПОРТФОЛИО ПРОЕКТОВ',
  text: `Мы создаём самую комфортную и престижную среду для жизни и работы. 
  Заботимся о клиенте на каждом этапе: при выборе, на стадии покупки, в процессе ожидания ключей и, конечно, 
  во время жизни и работы в наших домах. Мы смотрим на свою работу глазами клиента и создаём только то, 
  что устроило бы нас самих.`,
};

export const topUpperCaseText: string = `УНИКАЛЬНЫЕ ДОМА, ПРЕВОСХОДЯЩИЕ ОЖИДАНИЯ`;

export const textInBlockWithHalfSlider: TextBlockWithRedirectButtonType = {
  firstDescription: `Нам важно, как человек будет чувствовать себя в построенном нами доме. 
  Мы вкладываем душу в каждый элемент и продумываем всё до мелочей. 
  Наши продукты отличаются максимальной комплектацией и зачастую превосходят продукты конкурентов. 
  Мы стремимся сделать каждый элемент дома идеальным для детей и родителей, для добрососедства, 
  для здорового образа жизни, для автомобилей и велосипедов, для жителей и их гостей...для всех вас.`,
  redirectUrl: '/fine-development',
  btnName: 'ПОДРОБНЕЕ',
};

export const slidesHalfScreen: SlideType[] = [
  {
    id: 1,
    imgSrc: kaminChasy,
    alt: `Камин и часы в лобби дома «Малая Ордынка 19»`,
    caption: `Камин и часы в лобби дома «Малая Ордынка 19»`,
  },
  {
    id: 2,
    imgSrc: lobbiChistiePrydi,
    alt: `Лобби в доме «Чистые Пруды»`,
    caption: `Лобби в доме «Чистые Пруды»`,
  },
  {
    id: 3,
    imgSrc: pentkhausKaminomChistie,
    alt: `Интерьер квартиры в доме «Чистые Пруды»`,
    caption: `Интерьер квартиры в доме «Чистые Пруды»`,
  },
  {
    id: 4,
    imgSrc: parkingLavr,
    alt: `Паркинг в доме «Лаврушинский»`,
    caption: `Паркинг в доме «Лаврушинский»`,
  },
  {
    id: 5,
    imgSrc: friendsLabDostizhenie,
    alt: `Friend's Lab в доме «Достижение»`,
    caption: `Friend's Lab в доме «Достижение»`,
  },
  {
    id: 6,
    imgSrc: kidsLabOrdinka,
    alt: `Kid's Lab в доме «Малая Ордынка 19»`,
    caption: `Kid's Lab в доме «Малая Ордынка 19»`,
  },
  {
    id: 7,
    imgSrc: fitLabLavr,
    alt: `Fit Lab в доме «Лаврушинский»`,
    caption: `Fit Lab в доме «Лаврушинский»`,
  },
];

export const textInCircleOfProjects: TextBlockWithRedirectButtonType = {
  firstDescription:
    'Мы вкладываем душу в каждый проект: прорабатываем детали, продумываем инфраструктуру и подбираем материалы.',
  redirectUrl: '/fine-development',
  btnName: 'ПОДРОБНЕЕ',
};

export const contentInCircleOfProjects: ContentType = {
  mainMiddleText: '100',
  secondaryMiddleText: 'проектов',
  image: {
    imgSrc: houseFromVeranda,
    altSrc: `house From Veranda photo`,
  },
  heightContainerMobile: '185rem',
};

export const middleUpperCaseText: string = `МЫ ВСЕГДА СТРОИМ ТО,\u00A0ЧТО\u00A0ОБЕЩАЛИ`;
export const textWithMiddleUpperCaseText: TextBlockWithRedirectButtonType = {
  firstDescription: 'Сравните рендеры и живые фотографии',
};

export const BEFORE_AFTER_PHOTO_INFO_MOCK = [
  {
    id: 1,
    buildingName: 'Малая Ордынка 19',
    logoImg: ordynkaLogo,
    isDisabled: false,
    photos: [
      { id: 2, title: 'Фасад', beforeImg: ordynkaFacade11, afterImg: ordynkaFacade12 },
      { id: 3, title: 'Фасад', beforeImg: ordynkaFacade21, afterImg: ordynkaFacade22 },
      { id: 4, title: 'Двор', beforeImg: ordynkaDvor11, afterImg: ordynkaDvor12 },
      { id: 5, title: 'Двор', beforeImg: ordynkaDvor21, afterImg: ordynkaDvor22 },
      { id: 6, title: 'Лобби', beforeImg: ordynkaLobby1, afterImg: ordynkaLobby2 },
    ],
  },
  {
    id: 7,
    buildingName: 'Реномэ',
    logoImg: renomeLogo,
    isDisabled: false,
    photos: [
      { id: 8, title: 'Фасад', beforeImg: renomeFacade11, afterImg: renomeFacade12 },
      { id: 9, title: 'Двор', beforeImg: renomeDvor11, afterImg: renomeDvor12 },
      { id: 10, title: 'Двор', beforeImg: renomeDvor21, afterImg: renomeDvor22 },
      { id: 11, title: 'Лобби', beforeImg: renomeLobby11, afterImg: renomeLobby12 },
      { id: 12, title: 'Лобби', beforeImg: renomeLobby21, afterImg: renomeLobby22 },
    ],
  },
  {
    id: 15,
    buildingName: 'Квартал Jazz (скоро)',
    logoImg: JazzLogo,
    isDisabled: true,
    photos: [],
  },
];

export const textWithRedirectToProjects: TextBlockWithRedirectButtonType = {
  firstDescription: `Мы никогда не экономим на материалах и строительных решениях, 
  не меняем изначальный проект и гордимся тем, что фотографии готовых домов не отличаются 
  от рендеров в нашей рекламе.`,
  redirectUrl: '/realizatsiya-proyekta',
  btnName: 'ПОДРОБНЕЕ',
};

export const imageInBlockWithRedirectToProjects: ImageType = {
  imgSrc: receptionOrdinka,
  altSrc: `Ресепшн дома «Малая Ордынка 19»`,
  imageCaption: 'Ресепшн дома «Малая Ордынка 19»',
};

export const animatedUpperCaseText: string = `ЗАБОТА О КЛИЕНТЕ И\u00A0УЧЁТ ЕГО\u00A0ПОТРЕБНОСТЕЙ НА\u00A0КАЖДОМ\u00A0ЭТАПЕ`;
export const textWithAnimatedUpperCaseText: TextBlockWithRedirectButtonType = {
  firstDescription: `Мы с уважением относимся к своим клиентам и окружаем их заботой на каждом этапе: при выборе и покупке недвижимости, 
  во время ожидания и получения ключей, на стадии жизни и работы в наших домах.`,
};

export const dropdownItems: ItemTypes[] = [
  {
    id: 1,
    title: 'КОМФОРТ ВЫБОРА',
    firstDescription: `Сайты всех проектов Sminex максимально информативны, для непроданных лотов всегда указана стоимость. 
    Мы не скрываем объекты, не заставляем клиентов звонить, чтобы узнать цену, и не практикуем сбор контактов с последующими надоедливыми звонками.`,
    secondDescription: `У каждой квартиры и коммерческого помещения есть отдельная страница с несколькими вариантами планировок, 
    видами из окон, подробными характеристиками.`,
    imageSrc: girlWithCouple,
    altSrc: `girlWithCouple photo`,
  },
  {
    id: 2,
    title: 'КОМФОРТ ОБЩЕНИЯ',
    firstDescription: `В наших отделах продаж работают обученные, внимательные и вежливые сотрудники, досконально знающие рынок и свой продукт. 
    Они помогут сделать взвешенный выбор и найти оптимальный вариант.`,
    secondDescription: `Мы пунктуальны и всегда держим слово. Клиент может обсудить что-либо с сотрудником Sminex
     по любому удобному для него каналу связи, в том числе в мессенджерах, зная, что любые договорённости для нас равносильны подписанному договору.`,
    imageSrc: girlOnReception,
    altSrc: `girlOnReception photo`,
  },
  {
    id: 3,
    title: 'БЫТЬ В КУРСЕ',
    firstDescription: `После сделки мы информируем клиента о динамике строительства, напоминаем о плановых платежах при рассрочке, 
    интересуемся его мнением по продуктовым решениям, а персональный менеджер остаётся на связи в режиме 24/7.`,
    imageSrc: phone,
    altSrc: `phone photo`,
  },
];

export const textInCircleOfComfortable: TextBlockWithRedirectButtonType = {
  firstDescription: `Служба комфорта Sminex обеспечивает высокий уровень сервиса, 
  единые стандарты которого действуют во всех построенных нами домах и деловых центрах.`,
};

export const contentInCircleOfComfortable: ContentType = {
  topText: 'СЛУЖБА КОМФОРТА',
  mainMiddleText: '24/7',
  secondaryMiddleText: 'круглосуточная забота',
  image: {
    imgSrc: kamin,
    altSrc: `kamin photo`,
  },
};

export const textWithStatistics: TextBlockWithRedirectButtonType = {
  statistics: [
    {
      numericalIndicator: '550 000',
      unitOfMeasurement: 'м\u00B2',
      description: 'в управлении',
    },
    {
      numericalIndicator: '310',
      description: 'сотрудников',
    },
  ],
  firstDescription: `Служба комфорта — одно из подразделений Sminex, которое занимается содержанием наших домов. 
    Но это больше чем просто управляющая компания: она работает круглосуточно, обеспечивая максимальный 
    комфорт и премиальное
    качество жизни для наших клиентов.`,
  redirectUrl: '/sluzhba-komforta',
  btnName: 'ПОДРОБНЕЕ',
};

export const imageInBlockWithStatistics: ImageType = {
  imgSrc: enter,
  altSrc: `Двор дома Чистые Пруды`,
  imageCaption: 'Двор дома Чистые Пруды',
};

export const textInCircleOfCompany: TextBlockWithRedirectButtonType = {
  firstDescription: `Sminex обладает 23-летним опытом строительства жилой и коммерческой недвижимости. 
  За это время компания реализовала более 100 проектов площадью 8 млн кв. м.`,
  secondDescription: `Sminex был создан в 2007 году. В 2022 году приобрёл компанию «Интеко», 
  которая начала заниматься девелопментом в 2001 году. 
  Это позволило объединить многолетний опыт строительства домов и наработанные технологии.`,
  redirectUrl: '/realizatsiya-proyekta',
  btnName: 'ПОДРОБНЕЕ',
};

export const contentInCircleOfCompany: ContentType = {
  topText: 'ДЕВЕЛОПЕР, КОТОРОМУ МОЖНО ДОВЕРЯТЬ',
  mainMiddleText: '23',
  secondaryMiddleText: 'года опыт',
  widthTopText: '42.29167rem',
  heightContainer: '57.29167rem',
  heightContainerMobile: '188.5rem',
};

export const mockFhotos = [
  {
    id: 1,
    imgSrc: dvorRenome1,
    alt: `Двор дома «Реномэ»`,
    caption: `Двор дома «Реномэ»`,
  },
  {
    id: 2,
    imgSrc: dvorRenome2,
    alt: `Двор дома «Реномэ»`,
    caption: `Двор дома «Реномэ»`,
  },
  {
    id: 3,
    imgSrc: dvorRenome3,
    alt: `Двор дома «Реномэ»`,
    caption: `Двор дома «Реномэ»`,
  },
  {
    id: 4,
    imgSrc: dvorRenome4,
    alt: `Двор дома «Реномэ»`,
    caption: `Двор дома «Реномэ»`,
  },
  {
    id: 5,
    imgSrc: renome5,
    alt: `Входная группа дома «Реномэ»`,
    caption: `Входная группа дома «Реномэ»`,
  },
  {
    id: 6,
    imgSrc: ordynka1,
    alt: `Фасад дома «Малая Ордынка 19»`,
    caption: `Фасад дома «Малая Ордынка 19»`,
  },
  {
    id: 7,
    imgSrc: ordynka2,
    alt: `Фасад дома «Малая Ордынка 19»`,
    caption: `Фасад дома «Малая Ордынка 19»`,
  },
  {
    id: 8,
    imgSrc: ordynka3,
    alt: `Фасад дома «Малая Ордынка 19»`,
    caption: `Фасад дома «Малая Ордынка 19»`,
  },
  {
    id: 9,
    imgSrc: ordynka4,
    alt: `Входная группа дома «Малая Ордынка 19»`,
    caption: `Входная группа дома «Малая Ордынка 19»`,
  },
  {
    id: 10,
    imgSrc: ordynka5,
    alt: `Табличка дома «Малая Ордынка 19»`,
    caption: `Табличка дома «Малая Ордынка 19»`,
  },
  {
    id: 11,
    imgSrc: jazz,
    alt: `Детская площадка квартала JAZZ`,
    caption: `Детская площадка квартала JAZZ`,
  },
];
