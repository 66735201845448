import { Stylex$Create } from '@stylexjs/stylex/lib/StyleXTypes';

export interface IHeadingProps {
  header: string;
  style: Stylex$Create;
}

export const Heading = ({ header, style }: IHeadingProps) => {
  return <div {...style}>{header}</div>;
};
