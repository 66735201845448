import { IQrCode, QrCodeWithName } from '@/enteties/qr-code-with-name/ui';
import { Stylex$Create } from '@stylexjs/stylex/lib/StyleXTypes';

export interface IQrCodeBlock {
  qrCodes: IQrCode[];
  style: Stylex$Create;
}

export const QrCodeBlock = ({ qrCodes, style }: IQrCodeBlock) => {
  return (
    <div {...style}>
      {qrCodes.map((element, index) => (
        <QrCodeWithName key={index} qr={element} />
      ))}
    </div>
  );
};
