import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { PhotoFrameTextType } from '../model/types';
import useIsMobile from '@/shared/hooks/useIsMobile.tsx';

const PhotoFrameTextSection = ({
  header,
  description,
  imgSrc,
  caption,
  reverseOrder,
  secondDescription,
}: PhotoFrameTextType) => {
  const { isMobile } = useIsMobile();

  const getImageSrc = () => {
    if (typeof imgSrc === 'string') {
      return imgSrc;
    }
    return imgSrc ? (isMobile ? imgSrc.mobile : imgSrc.desktop) : '';
  };
  return (
    <div {...stylex.props(styles.photoTextContainer(reverseOrder))}>
      <div {...stylex.props(styles.textContainer(reverseOrder))}>
        {header && <h3 {...stylex.props(styles.h3)}>{header}</h3>}
        <div {...stylex.props(styles.descriptionBlock)}>
          {description && <div {...stylex.props(styles.descriptionContainer)}>{description}</div>}
          {secondDescription && <div {...stylex.props(styles.descriptionContainer)}>{secondDescription}</div>}
        </div>
      </div>
      <div {...stylex.props(styles.photoFrameContainer(reverseOrder))}>
        {imgSrc && <img src={getImageSrc()} {...stylex.props(styles.img(reverseOrder))}></img>}
        {caption && <div {...stylex.props(styles.captionContainer)}>{caption}</div>}
      </div>
    </div>
  );
};

export default PhotoFrameTextSection;
