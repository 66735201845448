import React, { useLayoutEffect, useRef } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ContentType } from '../model/types';
import { MiniPath } from './mini-path';
import { BigPath } from './big-path';
import { colors } from '@/shared/tokens/colors.stylex';
import useIsMobile from '@/shared/hooks/useIsMobile';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MobilePath } from './mobile-path';
import { ImageWithTextBlock } from './image-with-text-block';

gsap.registerPlugin(ScrollTrigger);

interface CircleProgressBarMainPageProps {
  bottomTextBlock: React.ReactNode;
  contentBlock: ContentType;
}

export const CircleProgressBarMainPage: React.FC<CircleProgressBarMainPageProps> = ({
  bottomTextBlock,
  contentBlock,
}) => {
  const miniPathRef = useRef<SVGPathElement | null>(null);
  const bigPathRef = useRef<SVGPathElement | null>(null);
  const pathMobRef = useRef<SVGPathElement | null>(null);
  const topTextRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const bottomTextRef = useRef<HTMLDivElement | null>(null);

  const { isMobile } = useIsMobile();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const lengthOne = miniPathRef.current?.getTotalLength();
      const lengthTwo = bigPathRef.current?.getTotalLength();
      const lengthMob = pathMobRef.current?.getTotalLength();

      isMobile
        ? gsap.set(pathMobRef.current, { strokeDasharray: lengthMob, strokeDashoffset: lengthMob })
        : (gsap.set(miniPathRef.current, { strokeDasharray: lengthOne, strokeDashoffset: lengthOne }),
          gsap.set(bigPathRef.current, { strokeDasharray: lengthTwo, strokeDashoffset: lengthTwo && lengthTwo }));

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: isMobile ? pathMobRef.current : miniPathRef.current,
          start: 'top 75%',
          end: 'top top',
          scrub: 0.7,
          invalidateOnRefresh: true,
        },
      });

      isMobile
        ? tl.add('start').to(pathMobRef.current, { strokeDashoffset: '0' }, 'start')
        : tl
            .add('start')
            .to(miniPathRef.current, { strokeDashoffset: '0' }, 'start')
            .to(bigPathRef.current, { strokeDashoffset: '0' });

      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: isMobile ? pathMobRef.current : miniPathRef.current,
          start: 'top 90%',
          end: 'top 50%',
          scrub: 3,
          invalidateOnRefresh: true,
          once: true,
        },
      });

      const targets = [topTextRef.current, imageRef.current].filter(Boolean);

      tl2
        .add('start')
        .fromTo(targets, { y: '100%' }, { y: '0%', duration: 0.5, ease: 'power3.out' }, 'start')
        .fromTo(
          targets,
          {
            clipPath: 'inset(0% 0% 100% 0%)',
            opacity: 1,
          },
          {
            clipPath: 'inset(0% 0% 0% 0%)',
            duration: 0.5,
            ease: 'power3.inOut',
          },
          'start'
        )
        .fromTo(
          bottomTextRef.current && bottomTextRef.current,
          { y: '100%' },
          { y: '0%', duration: 0.5, ease: 'power3.out' },
          'start'
        )
        .fromTo(
          bottomTextRef.current && bottomTextRef.current,
          {
            clipPath: 'inset(0% 0% 100% 0%)',
            opacity: 1,
          },
          {
            clipPath: 'none',
            duration: 0.5,
            ease: 'power3.inOut',
          },
          'start'
        );
    });

    return () => ctx.revert();
  }, [isMobile]);

  return (
    <div {...stylex.props(styles.container(contentBlock.heightContainer, contentBlock.heightContainerMobile))}>
      <div {...stylex.props(styles.circleBlock)}>
        {isMobile ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 375 459"
            {...stylex.props(styles.svgMob)}
          >
            <MobilePath strokeColor={colors.brandBackground} />
            <MobilePath strokeColor={colors.primaryIcon} pathRef={pathMobRef} />
          </svg>
        ) : (
          <svg width="100%" height="100%" viewBox="0 0 805 805" xmlns="http://www.w3.org/2000/svg">
            <MiniPath strokeColor={colors.brandBackground} />
            <MiniPath strokeColor={colors.primaryIcon} pathRef={miniPathRef} />
            <BigPath strokeColor={colors.brandBackground} />
            <BigPath strokeColor={colors.primaryIcon} pathRef={bigPathRef} />
          </svg>
        )}

        <div {...stylex.props(styles.middleTextBlock)}>
          <p {...stylex.props(styles.mainMiddleText)}>{contentBlock.mainMiddleText}</p>
          <p {...stylex.props(styles.secondaryMiddleText)}>{contentBlock.secondaryMiddleText}</p>
        </div>

        {contentBlock.topText && (
          <div {...stylex.props(styles.topTextBlock(contentBlock.widthTopText))} ref={topTextRef}>
            <p {...stylex.props(styles.topText)}>{contentBlock.topText}</p>
          </div>
        )}

        <ImageWithTextBlock
          image={contentBlock.image}
          imageRef={imageRef}
          bottomTextBlock={bottomTextBlock}
          bottomTextRef={bottomTextRef}
        />
      </div>
    </div>
  );
};
