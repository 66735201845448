import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface StatisticsBlockProps {
  numericalIndicator: string;
  unitOfMeasurement?: string;
  description: string;
}

export const StatisticsBlock: React.FC<StatisticsBlockProps> = ({
  numericalIndicator,
  unitOfMeasurement,
  description,
}) => {
  return (
    <div {...stylex.props(styles.squareContainer)}>
      <div {...stylex.props(styles.squareBlock)}>
        <p {...stylex.props(styles.numericalIndicator)}>{numericalIndicator}</p>
        {unitOfMeasurement && <p {...stylex.props(styles.unitOfMeasurement)}>{unitOfMeasurement}</p>}
      </div>
      <p {...stylex.props(styles.textInSquareContainer)}>{description}</p>
    </div>
  );
};
