import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { TextBlockWithRedirectButtonType } from '../model/types';
import { Separator } from './separator';
import { StatisticsBlock } from './statistics-block';
import { TextAndButton } from './text-and-button';

interface TextBlockWithRedirectButtonProps {
  content: TextBlockWithRedirectButtonType;
}

export const TextBlockWithRedirectButton: React.FC<TextBlockWithRedirectButtonProps> = ({ content }) => {
  const { isMobile } = useIsMobile();
  return (
    <div {...stylex.props(styles.container)}>
      {content.statistics && (
        <div {...stylex.props(styles.statisticsContainer)}>
          {!isMobile && <Separator />}
          {content.statistics[0] && (
            <StatisticsBlock
              numericalIndicator={content.statistics[0].numericalIndicator}
              unitOfMeasurement={content.statistics[0].unitOfMeasurement}
              description={content.statistics[0].description}
            />
          )}
          {content.statistics.length > 1 && <Separator />}
          {content.statistics[1] && (
            <StatisticsBlock
              numericalIndicator={content.statistics[1].numericalIndicator}
              unitOfMeasurement={content.statistics[1].unitOfMeasurement}
              description={content.statistics[1].description}
            />
          )}

          {!isMobile && <Separator />}
        </div>
      )}
      {(content.firstDescription || (content.btnName && content.redirectUrl)) && (
        <TextAndButton
          firstDescription={content.firstDescription}
          secondDescription={content.secondDescription}
          btnName={content.btnName}
          redirectUrl={content.redirectUrl}
        />
      )}
    </div>
  );
};
