import styles from './tabs.module.css';
import { PortfolioGroup } from '../model/types';

interface TabsProps {
  items: PortfolioGroup[];
  onItemClick: (item: PortfolioGroup['name']) => void;
}

export const Tabs: React.FC<TabsProps> = ({ items, onItemClick }) => {
  return (
    <>
      <ul className={styles.navList}>
        {items?.map((item, key: number) => (
          <li
            key={key}
            className={[styles.navListItem, item.active && styles.isActive].join(' ')}
            onClick={() => !item.active && onItemClick(item.name)}
          >
            {item.name} <span className={styles.navListItemDot}>•</span>{' '}
            <span className={styles.navListItemNumber}>{item.count}</span>
          </li>
        ))}
      </ul>
    </>
  );
};
