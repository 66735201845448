import { ITextParagraph, TextParagraph } from '@/enteties/text-paragraph/ui';

export interface ITextParagraphsProps {
  blocks: ITextParagraph[];
}

export const TextParagraphs = ({ blocks }: ITextParagraphsProps) => {
  return (
    <>
      {blocks.map((block, index) => (
        <TextParagraph key={index} block={block} />
      ))}
    </>
  );
};
