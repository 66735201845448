import styles from './cards.module.css';
import { Slider } from './slider';
import { PortfolioItem } from '../model/types';

interface CardsProps {
  items: PortfolioItem[];
  onMouseOver: (item: PortfolioItem['name']) => void;
  onMouseLeave: (item: PortfolioItem['name']) => void;
}

export const Cards: React.FC<CardsProps> = ({ items, onMouseOver, onMouseLeave }) => {
  return (
    <>
      <div className={styles.projectCardsSection}>
        {items?.length > 0 ? (
          items?.map((item, key) => (
            <div key={key} className={styles.cardItem}>
              <div
                className={styles.cardImgConteiner}
                onMouseOver={() => onMouseOver(item.name)}
                onMouseLeave={() => onMouseLeave(item.name)}
              >
                <div className={styles.cardImgConteinerStatusText}>{item.statusText}</div>
                <div className={styles.cardImgConteinerLogo}>
                  <div className={styles.houseNameImg}>{<item.logo />}</div>
                </div>
                <Slider photos={item.photos} url={item.url} />
              </div>
              <div className={styles.houseName}>
                <div className={styles.houseNameSlogan} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                <div className={styles.houseNameAddress}>
                  <address>{item.map.address}</address>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ width: 'var(--rem-desktop-1920-in-508px)' }}></div>
        )}
      </div>
    </>
  );
};
