import styles from './maps.module.css';
import { PortfolioItem, PortfolioGroup } from '../model/types';
import { useEffect, useRef } from 'react';

type itemsMap = PortfolioItem['map'] & {
  name: PortfolioItem['name'];
  url: PortfolioItem['url'];
  status: PortfolioItem['status'];
};

interface MapsProps {
  items: itemsMap[];
  /**
   * Статус открытия карты
   */
  isMapOpen: boolean;
  /**
   * Изменения состояния открытия/закрытия карты
   */
  toggleMap: (active: boolean) => void;
  itemsTabs: PortfolioGroup[];
  onItemTabClick: (item: PortfolioGroup['name']) => void;
}

const Maps: React.FC<MapsProps> = ({ items, isMapOpen, toggleMap, itemsTabs, onItemTabClick }) => {
  const mapContainerRef = useRef<HTMLDivElement>(null); // Контейнер для карты
  const mapRef = useRef<any>(null); // Экземпляр карты
  const placemarksRef = useRef<any[]>([]); // Список текущих меток
  const zoomRef = useRef<HTMLDivElement>(null);
  const mediaPoint = matchMedia('(max-width: 767px)');
  const mediaPoint4K = matchMedia('(min-width: 3840px)');
  const getZoom = () => {
    if (mediaPoint4K.matches) {
      return 14;
    }
    if (mediaPoint.matches) {
      return 10;
    }
    return 11;
  };
  const mapState = {
    center: !mediaPoint.matches ? [55.747, 37.60346] : [55.769204, 37.618431],
    zoom: getZoom(),
    behaviors: ['drag', 'multiTouch'],
    controls: [],
  };
  const opyionsMap = {
    suppressMapOpenBlock: true,
    searchControlProvider: 'yandex#search',
  };

  useEffect(() => {
    const ymaps = (window as any).ymaps;
    if (!mapRef.current && mapContainerRef.current) {
      ymaps.ready(() => {
        if (!mapRef.current && mapContainerRef.current) {
          mapRef.current = new ymaps.Map(mapContainerRef.current, mapState, opyionsMap);
          mapRef.current.behaviors.disable('scrollZoom');

          const MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
            `<div class="${styles.placemark}" data-placemark data-placemark-code="$[properties.code]"  tabindex="0" data-map-marker="$[properties.category]">
                <div class="${styles.placemarkInner}">
                  <div class="${styles.placemarkIcon}">
                    <img src="$[properties.icon]" width="$[properties.width]" height="$[properties.height]">
                  </div>
                </div>
                <div class="${styles.placemarkHover}">$[properties.title]</div>
              </div>`,
            {
              build: function () {
                MyIconContentLayout.superclass.build.call(this);
                const placemark = this.getParentElement().getElementsByClassName(styles.placemark)[0];
                this.getData().geoObject.events.add(
                  'click',
                  () => {
                    mapRef.current.container._mapElement
                      .querySelectorAll(`.${styles.placemark}`)
                      .forEach((placemark: Element) => {
                        (placemark as HTMLElement).classList.remove(styles.isActive);
                      });
                    placemark.classList.add(styles.isActive);
                  },
                  this
                );
              },
            }
          );

          const BalloonContentLayout = ymaps.templateLayoutFactory.createClass(
            `
              <a class="${styles.placemarkBalloonInner}" 
                {% if properties.linkHref %}
                    href="$[properties.linkHref]"
                {% endif %}
              >
                {% if properties.imagePath %}
                  <div class="${styles.placemarkBalloonImage}">
                    <img src="$[properties.imagePath]" srcset="$[properties.imagePath] 2x" width="101" height="94" alt="$[properties.imageAlt]">
                  </div>
                  {% endif %}
                  <div class="${styles.placemarkBalloonWrap}">
                  <p class="${styles.placemarkBalloonText}">$[properties.sign]</p>
                  <p class="${styles.placemarkBalloonTitle}">$[properties.title]</p>
                  <p class="${styles.placemarkBalloonText}">$[properties.text]</p>
                  </div>
                  <div class="${styles.placemarkBalloonClose}"></div>
                </a>
              `
          );

          const MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
            `<div class="${styles.placemarkBalloon} ${styles.hidden}">
              $[[options.contentLayout]]
            </div>`,
            {
              build: function () {
                BalloonContentLayout.superclass.build.call(this);
                const parentElement = this.getParentElement();
                const balloon = parentElement.querySelector(`.${styles.placemarkBalloon}`);
                const close = parentElement.querySelector(`.${styles.placemarkBalloonClose}`);

                setTimeout(() => {
                  balloon?.classList.remove(styles.hidden);
                }, 10);

                const onMapClick = () => {
                  removeActivePlacemark();
                  balloon.classList.add(styles.hidden);
                  (window as any).activeEl = null;
                  setTimeout(() => {
                    mapRef.current?.balloon.close();
                  }, 300);
                };

                const onCloseClick = (e: MouseEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeActivePlacemark();
                  balloon.classList.add(styles.hidden);
                  (window as any).activeEl = null;
                  setTimeout(() => {
                    mapRef.current?.balloon.close();
                  }, 300);
                };

                const removeActivePlacemark = () => {
                  mapRef.current.container._mapElement
                    .querySelectorAll(`.${styles.placemark}`)
                    .forEach((placemark: Element) => {
                      (placemark as HTMLElement).classList.remove(styles.isActive);
                    });
                };

                mapRef.current.events.group().add('click', onMapClick);
                close.addEventListener('click', onCloseClick);
              },

              clear() {
                mapRef.current.events.group().events.types.click = undefined;
                mapRef.current.events.group().removeAll();
                BalloonContentLayout.superclass.clear.call(this);
              },
            }
          );

          const myZoomControl = new ymaps.control.ZoomControl({
            options: {
              layout: ymaps.templateLayoutFactory.createClass(zoomRef.current),
            },
          });

          mapRef.current.controls.add(myZoomControl);

          // Сохраняем макеты в реф для использования при создании меток
          mapRef.current.customLayouts = { MyIconContentLayout, MyBalloonLayout, BalloonContentLayout };
        }
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.events.group().removeAll();
        mapRef.current.destroy();
        mapRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    document.querySelectorAll(`.${styles.placemark}`).forEach((placemark) => {
      placemark.classList.add(styles.isActive);
    });

    const ymaps = (window as any).ymaps;
    ymaps.ready(() => {
      if (mapRef.current && items) {
        if (placemarksRef.current.length > 0) {
          placemarksRef.current.forEach((placemark) => mapRef.current.geoObjects.remove(placemark));
          placemarksRef.current = [];
          renderPlacemarks(ymaps);
          //   const mapMarkers = document.querySelectorAll('[data-map-marker]');
          //   mapMarkers.forEach((marker) => {
          //     marker.classList.add(styles.hidden);
          //   });
        } else {
          renderPlacemarks(ymaps);
        }
      }
    });
  }, [items]);

  const renderPlacemarks = (ymaps: any) => {
    const { MyIconContentLayout, MyBalloonLayout, BalloonContentLayout } = mapRef.current.customLayouts || {};

    const iconImageSize = [generetePX(65), generetePX(75)];
    const iconImageOffset = [-generetePX(32.5), -generetePX(75)];

    const newPlacemarks = items.map((obj) => {
      const markers = new ymaps.Placemark(
        obj.latLng,
        {
          title: obj.name,
          sign: obj.signature,
          text: obj.address,
          linkHref: obj.url,
          imagePath: obj.imagePath,
          imageAlt: obj.name,
          category: obj.status,
          icon: obj.pointImg,
          code: obj.name,
        },
        {
          // Опции.
          // Необходимо указать данный тип макета.
          iconLayout: 'default#imageWithContent',
          // Своё изображение иконки метки.
          iconImageHref: '',
          // Размеры метки.
          iconImageSize: iconImageSize, // [65, 75],
          // Смещение левого верхнего угла иконки относительно`
          // её "ножки" (точки привязки).
          iconImageOffset: iconImageOffset, // [-32.5, -75],
          // Смещение слоя с содержимым относительно слоя с картинкой.
          // iconContentOffset: [15, 15],
          iconContentLayout: MyIconContentLayout,
          balloonLayout: MyBalloonLayout,
          balloonContentLayout: BalloonContentLayout,
          hideIconOnBalloonOpen: false,
          balloonPanelMaxMapArea: 0,
          balloonOffset: [0, 0],
        }
      );

      // перемещение до markers
      markers.events.add('click', () => {
        mapRef.current.panTo(obj.latLng, { duration: 500 });
      });

      return markers;
    });

    newPlacemarks.forEach((placemark) => mapRef.current.geoObjects.add(placemark));
    placemarksRef.current = newPlacemarks;
  };

  const generetePX = (px: number) => {
    const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    let width = 19.2;
    if (rem < 3.78) {
      width = 3.75;
    }
    const pixelValue = (px / width) * rem;
    return pixelValue;
  };

  const zoomIn = () => {
    const map = mapRef.current?.getZoom ? mapRef.current : mapRef.current?.geoObjects.getMap();
    if (map) {
      map.setZoom(map.getZoom() + 1, { checkZoomRange: true });
    }
  };

  const zoomOut = () => {
    const map = mapRef.current?.getZoom ? mapRef.current : mapRef.current?.geoObjects.getMap();
    if (map) {
      map.setZoom(map.getZoom() - 1, { checkZoomRange: true });
    }
  };

  const handleToggleMap = () => {
    toggleMap(!isMapOpen);
    (document.querySelector('footer') as HTMLElement).style.display = !isMapOpen ? 'none' : '';
    (document.querySelector('body') as HTMLElement).style.overflow = !isMapOpen ? 'hidden' : '';

    // создаем переменную для css (--scrollbar-width), чтобы при закрытие map не было скролла по оси X (высчитывается в mapWrapper max-width)
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);

    // после изменение размера обновляю размер карты
    setTimeout(() => {
      const intervalId = setInterval(() => {
        mapRef.current.container.fitToViewport();
      }, 10);
      setTimeout(() => {
        clearInterval(intervalId);
      }, 500);
    }, 0);
  };

  return (
    <>
      <div className={[styles.mapWrapper, isMapOpen ? styles.open : ''].join(' ')}>
        <div ref={mapContainerRef} className={[styles.map].join(' ')}>
          {items && (
            <button
              className={styles.openBtn}
              type="button"
              aria-label={isMapOpen ? 'Показать список' : 'Развернуть карту'}
              onClick={handleToggleMap}
            >
              <svg viewBox="0 0 32 32" fill="none">
                <path
                  d="M9.33333 21.3335L4 16.0002M4 16.0002L9.33333 10.6668M4 16.0002L28 16.0002"
                  stroke="currentColor"
                />
              </svg>
              <span>{isMapOpen ? 'Показать список' : 'Развернуть карту'}</span>
            </button>
          )}
          <div className={styles.mapZoom} ref={zoomRef}>
            <button className={styles.mapZoomBtn} type="button" aria-label="Приблизить карту" onClick={zoomIn}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
                <path d="M20 35L20 5M35 20L20 20L5 20" stroke="currentColor" />
              </svg>
            </button>
            <button className={styles.mapZoomBtn} type="button" aria-label="Отдалить карту" onClick={zoomOut}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
                <path d="M35 20L20 20L5 20" stroke="currentColor" />
              </svg>
            </button>
          </div>
          <div className={styles.mapFilter}>
            <ul className={styles.mapFilterNavList}>
              {itemsTabs &&
                itemsTabs.map((item, key) => (
                  <li
                    key={key}
                    className={[styles.mapFilterNavListItem, item.active && styles.isActive].join(' ')}
                    onClick={() => onItemTabClick(item.name)}
                  >
                    <button className={styles.mapFilterNavListItemBtn} type="button">
                      {item.name} <span className={styles.mapFilterNavListItemBtnDot}>•</span> {item.count} шт.
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maps;
