import { useEffect, useRef, useState } from 'react';
import { PortfolioItem } from '../../model/types';
import Swiper from 'swiper';
import { nanoid } from 'nanoid';
import { SwiperOptions } from 'swiper/types';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import styles from './style.module.css';

interface SliderProps {
  photos: PortfolioItem['photos'];
  url: PortfolioItem['url'];
}

export const Slider: React.FC<SliderProps> = ({ photos = [], url = '#' }) => {
  const id = nanoid();
  const swiperRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null);
  let observerSwiper;

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (swiper: Swiper) => {
    setCurrentSlide(swiper.realIndex);
  };

  const defaultOptions: SwiperOptions = {
    modules: [EffectFade, Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    watchOverflow: true,
    allowTouchMove: true,
    watchSlidesProgress: true,
    spaceBetween: 1,
    speed: 800,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    runCallbacksOnInit: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: `div[data-next='${id}']`,
      prevEl: `div[data-prev='${id}']`,
    },
    pagination: {
      el: null,
      type: 'custom',
      renderCustom: function (_, current, total) {
        const generateSvg = (isActive: boolean) => `
          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
            <circle cx="3" cy="3" r="1" fill="white"/>
            <circle cx="3" cy="3" r="${isActive ? 2.5 : 0.5}" stroke="white"/>
          </svg>
        `;
        const repeatedSvg = [...Array(total)].map((_, index) => generateSvg(current - 1 === index)).join('');
        return repeatedSvg;
      },
    },
    on: { slideChange: handleSlideChange },
  };

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = new Swiper(swiperRef.current, {
        ...defaultOptions,
        pagination: {
          ...(typeof defaultOptions.pagination === 'object' ? defaultOptions.pagination : {}),
          el: paginationRef.current,
        },
      });

      swiper.on('touchEnd', (s) => {
        if (s.swipeDirection === 'next') {
          timeout(s.navigation.nextEl, s);
        }
        if (s.swipeDirection === 'prev') {
          timeout(s.navigation.prevEl, s);
        }
      });

      // отключение autoplay и вкл когда прокрутили до нужного swiper
      swiper.autoplay.stop();
      observerSwiper = new IntersectionObserver(
        function (entries, self) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (entry.target.swiper) {
                entry.target.swiper.autoplay.start();
              }
            } else {
              if (entry.target.swiper) entry.target.swiper.autoplay.stop();
            }
          });
        },
        {
          root: null,
          threshold: 0.9,
          rootMargin: '0px',
        }
      );
      observerSwiper.observe(swiperRef.current);

      setSwiperInstance(swiper);

      return () => {
        if (swiper.navigation.nextEl?.timeoutHandle) {
          clearTimeout(swiper.navigation.nextEl.timeoutHandle);
        }
        if (swiper.navigation.prevEl?.timeoutHandle) {
          clearTimeout(swiper.navigation.prevEl?.timeoutHandle);
        }
        swiper.destroy(true, true);
      };
    }
  }, [photos]);

  useEffect(() => {
    // Заготовка для усложенния логики
    if (swiperInstance) {
      swiperInstance.update();
    }
  }, []);

  const onClickPrev = () => {
    if (!swiperRef.current) return;
    timeout(swiperRef.current.swiper.navigation.prevEl, swiperRef.current.swiper);
  };

  const onClickNext = () => {
    if (!swiperRef.current) return;
    timeout(swiperRef.current.swiper.navigation.nextEl, swiperRef.current.swiper);
  };

  function timeout(el, swiper) {
    const nextEl = swiper.navigation.nextEl;
    const prevEl = swiper.navigation.prevEl;
    swiper.autoplay.stop();
    if (prevEl.timeoutHandle) {
      clearTimeout(prevEl.timeoutHandle);
    }
    if (nextEl.timeoutHandle) {
      clearTimeout(nextEl.timeoutHandle);
    }
    el.timeoutHandle = setTimeout(() => {
      el.timeoutHandleel = null;
      swiper.autoplay.start();
    }, 10000);
  }

  return (
    <>
      <div ref={swiperRef} className={`swiper ${styles.swiper}`}>
        <div className="swiper-wrapper">
          {photos.map((item, key: number) => (
            <div key={key} className={`swiper-slide ${styles.slide}`}>
              <img src={item.src} alt={item.alt} className={`${styles.img}`} loading="lazy" />
              <div className="swiper-lazy-preloader"></div>
              <a href={url} className={styles.url}></a>
            </div>
          ))}
        </div>

        <div ref={paginationRef} className={`swiper-pagination ${styles.sliderPagination}`}></div>

        <div data-prev={id} className={`swiper-button-prev ${styles.prev}`} onClick={onClickPrev}>
          <span className={styles.btn}>
            <svg viewBox="0 0 32 32" fill="none">
              <path d="m12 4 11.3 11.3a1 1 0 0 1 0 1.4L12 28" stroke="currentColor" />
            </svg>
          </span>
        </div>

        <div data-next={id} className={`swiper-button-next ${styles.next}`} onClick={onClickNext}>
          <span className={styles.btn}>
            <svg viewBox="0 0 32 32" fill="none">
              <path d="m12 4 11.3 11.3a1 1 0 0 1 0 1.4L12 28" stroke="currentColor" />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};
