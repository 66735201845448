import styles from './styles.module.css';
import { ImageWithHeadingType } from '../model/types';
/**
 * GSITE-237 - "Полноэкранное изображение с заголовком по центру"
 * Компонент ImageWithHeadingSection отображает секцию с изображением и заголовком по центру
 *
 * @param {object} backgroundImageUrl - Объект с URL изображений для разных разрешений экрана
 * @param {string} backgroundImageUrl.desktop - URL изображения для десктопной версии
 * @param {string} backgroundImageUrl.mobile - URL изображения для мобильной версии
 * @param {string} heading - Заголовок, отображаемый по центру изображения
 *
 * @example
 * <ImageWithHeadingSection
 *   backgroundImageUrl={{
 *     desktop: "/path/to/desktop-image.jpg",
 *     mobile: "/path/to/mobile-image.jpg"
 *   }}
 *   heading="Заголовок секции"
 * />
 */

const ImageWithHeadingSection = ({ backgroundImageUrl, heading }: ImageWithHeadingType) => {
  return (
    <div className={`${styles.container} ${heading ? styles.containerWithHeading : ''}`}>
      {backgroundImageUrl && <img src={backgroundImageUrl.desktop} className={styles.imgContainer} />}
      {backgroundImageUrl && <img src={backgroundImageUrl.mobile} className={styles.imgMobileContainer} />}
      {heading && <h2 className={styles.h2}>{heading}</h2>}
    </div>
  );
};

export default ImageWithHeadingSection;
