import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import useIsMobile from '@/shared/hooks/useIsMobile';

interface ImageWithTextBlockProps {
  image?: {
    imgSrc: string;
    altSrc: string;
  };
  imageRef?: React.Ref<HTMLImageElement> | null;
  bottomTextBlock: React.ReactNode;
  bottomTextRef?: React.Ref<HTMLDivElement> | null;
}

export const ImageWithTextBlock: React.FC<ImageWithTextBlockProps> = ({
  image,
  imageRef,
  bottomTextBlock,
  bottomTextRef,
}) => {
  const { isMobile } = useIsMobile();
  return (
    <>
      {isMobile ? (
        <div {...stylex.props(styles.mobImageAndTextBlock)}>
          {image && (
            <img
              src={image.imgSrc}
              alt={image.altSrc}
              draggable={false}
              {...stylex.props(styles.imgBlock)}
              ref={imageRef}
            />
          )}
          <div {...stylex.props(styles.bottomTextBlock)} ref={bottomTextRef}>
            {bottomTextBlock}
          </div>
        </div>
      ) : (
        <>
          {image && (
            <img
              src={image.imgSrc}
              alt={image.altSrc}
              draggable={false}
              {...stylex.props(styles.imgBlock)}
              ref={imageRef}
            />
          )}
          <div {...stylex.props(styles.bottomTextBlock)} ref={bottomTextRef}>
            {bottomTextBlock}
          </div>
        </>
      )}
    </>
  );
};
