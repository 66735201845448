import React from 'react';
import styles from './styles.module.css';

interface TitleProps {
  title?: string;
  mainColor?: string;
}

export const Title: React.FC<TitleProps> = ({ title, mainColor }) => {
  return (
    <>
      {title && (
        <h2 className={styles.title} style={{ color: mainColor ? mainColor : '' }}>
          {title}
        </h2>
      )}
    </>
  );
};
