import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    padding: '0rem 3.125rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    width: '100%',
    zIndex: '1',

    '@media (max-width: 767px)': {
      padding: '0rem 4.26667rem',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  imageBlock: {
    width: '46.25rem',
    height: '34.79167rem',
    position: 'relative',
    overflow: 'hidden',
  },
  img: (isActive) => ({
    objectFit: 'cover',
    position: 'absolute',
    width: '46.25rem',
    height: '34.79167rem',
    opacity: isActive ? '1' : '0',
    transition: 'opacity 0.4s ease-in-out',

    '@media (max-width: 767px)': {
      position: 'static',
      width: '100%',
      height: '73.89387rem',
    },
  }),
  dropdownsContainer: {
    display: 'flex',
    width: '38.33333rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: `0.05208rem solid ${colors.tertiaryBorder}`,

    '@media (max-width: 767px)': {
      width: '100%',
      borderBottom: `0.26667rem solid ${colors.tertiaryBorder}`,
    },
  },
  itemDropdownBlock: {
    width: '100%',
  },
  titleBlock: (isOpen) => ({
    display: 'flex',
    padding: '1.66667rem 0rem 1.66667rem 0rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderTop: `0.05208rem solid ${colors.tertiaryBorder}`,
    position: 'relative',
    cursor: 'pointer',
    color: isOpen ? colors.primaryText : colors.secondaryText,
    transition: 'color 0.3s ease',

    ':hover': {
      color: isOpen ? colors.primaryText : colors.ghostText,
      transition: 'color 0.4s ease',
    },
    ':hover span': {
      color: isOpen ? colors.secondaryIcon : colors.ghostText,
    },

    '@media (max-width: 767px)': {
      padding: '5.33333rem 0rem 5.33333rem 0rem',
      borderTop: `0.26667rem solid ${colors.tertiaryBorder}`,
      '-webkit-tap-highlight-color': 'transparent',
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none',

      ':hover': {
        color: isOpen ? colors.primaryText : colors.secondaryText,
        transition: 'none',
      },
      ':hover span': {
        color: colors.secondaryIcon,
      },
    },
  }),
  title: {
    fontFamily: fonts.din2014Light,
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.66667rem',
    userSelect: 'none',

    '@media (max-width: 767px)': {
      fontSize: '5.33333rem',
      lineHeight: '6.4rem',
    },
  },
  plusIcon: (openItem) => ({
    width: '1.25rem',
    height: '1.25rem',
    position: 'absolute',
    right: '1.25rem',
    transform: openItem ? 'rotate(135deg)' : '',
    transition: 'transform 0.3s ease, color 0.4s ease',
    color: colors.secondaryIcon,

    '@media (max-width: 767px)': {
      width: '6.4rem',
      height: '6.4rem',
      right: '6.4rem',
    },
  }),
  descriptionBlock: (contentHeight, isOpen) => ({
    width: '29.94792rem',
    padding: isOpen ? '0.41667rem 0rem 1.66667rem 0rem' : '0rem 0rem 0rem 0rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxHeight: isOpen ? `${contentHeight + 2.08333}rem` : '0',
    overflow: 'hidden',
    transition: 'max-height 0.4s ease, padding 0.4s ease',

    '@media (max-width: 767px)': {
      width: '100%',
      padding: isOpen ? '1.06667rem 0rem 5.33333rem 0rem' : '0rem 0rem 0rem 0rem',
      gap: '6.4rem',
      maxHeight: isOpen ? `${contentHeight + 6.4}rem` : '0',
    },
  }),
  paragraphsBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.83333rem',

    '@media (max-width: 767px)': {
      gap: '4.26667rem',
    },
  },
  description: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833rem',

    '@media (max-width: 767px)': {
      fontSize: '4.26667rem',
      lineHeight: '6.4rem',
    },
  },
});
