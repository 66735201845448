import { ComponentConfig } from '@/shared/libs/dynamic-components-render.tsx';
import { navSections } from '@/shared/mockData/comfort-service-mock';
import styles from '@pages/comfort-service-page/ui/comfort-service-page.module.css';
import stylex from '@stylexjs/stylex';
import { style } from '../ui/styles';
import {
  BEFORE_AFTER_MOCK1,
  blocks,
  CIRCLED_PROGRESS_BAR_MOCK,
  header,
  MOCK_SWITCH,
  mockSlides,
  PHOTO_FRAME_TEXT_SECTION_MOCK,
  qrCodes,
  SECTION_WITH_TEXT_ANIMATION,
  url,
} from './mock-blocks';
import sminexImg from '@shared/ui/assets/sminex.png';

export interface CommonProps {
  [key: string]: unknown;
}

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'section',
    props: { className: styles.navContainer },
    children: [
      {
        component: 'CardWithFloatBtn',
        props: {
          btnName: 'УЗНАТЬ ПОДРОБНО',
          redirectUrl: '/',
          firstSentence: 'Обеспечиваем премиальное качество жизни',
          title: 'СЛУЖБА КОМФОРТА',
        },
      },
      {
        component: 'Navbar',
        props: { navSections, centeredSections: true },
      },
    ],
  },
  {
    component: 'SliderDefault',
    props: {
      images: mockSlides,
    },
  },
  {
    component: 'SectionWithTextAnimation',
    props: {
      textFirst: SECTION_WITH_TEXT_ANIMATION.textFirst,
      header: SECTION_WITH_TEXT_ANIMATION.header,
      id: SECTION_WITH_TEXT_ANIMATION.id,
      sluzbaKomfortaPage: true,
    },
  },
  {
    component: 'section',
    props: {},
    children: [
      {
        component: 'PhotoFrameTextSection',
        props: {
          header: PHOTO_FRAME_TEXT_SECTION_MOCK.header,
          description: PHOTO_FRAME_TEXT_SECTION_MOCK.description,
          secondDescription: PHOTO_FRAME_TEXT_SECTION_MOCK.secondDescription,
          imgSrc: PHOTO_FRAME_TEXT_SECTION_MOCK.imgSrc,
        },
      },
    ],
  },
  {
    component: 'section',
    props: { ...stylex.props(style.circledProgressBarWrapper) },
    children: [
      {
        component: 'InfoBlocksForProgressBar',
        props: { content: CIRCLED_PROGRESS_BAR_MOCK },
      },
      {
        component: 'CircledProgressBar',
        props: { switcherInfoBlocks: MOCK_SWITCH },
      },
    ],
  },
  {
    component: 'section',
    props: { ...stylex.props(style.sliderWrapper) },
    children: [
      {
        component: 'BeforeAfterOneSlide',
        props: {
          ...BEFORE_AFTER_MOCK1,
        },
      },
    ],
  },
  {
    component: 'section',
    props: { ...stylex.props(style.twoColls) },
    children: [
      {
        component: 'Heading',
        props: { header: header, style: { ...stylex.props(style.header) } },
      },
      {
        component: 'column',
        props: { ...stylex.props(style.content) },

        children: [
          {
            component: 'column',
            props: { ...stylex.props(style.infoBlock) },
            children: [
              {
                component: 'TextParagraphs',
                props: { blocks: blocks },
              },
              {
                component: 'QrCodeBlock',
                props: { qrCodes, style: { ...stylex.props(style.qrCodes) } },
              },
            ],
          },
          {
            component: 'VideoElement',
            props: {
              url: url,
              style: { ...stylex.props(style.videoBlock) },
            },
          },
        ],
      },
    ],
  },
  {
    component: 'TransitionCard',
    props: {
      bgUrl: sminexImg,
      title: 'ДЕВЕЛОПЕР, КОТОРОМУ МОЖНО ДОВЕРЯТЬ',
      link: '/realizatsiya-proyekta',
    },
  },
];
