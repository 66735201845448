import { Stylex$Create } from '@stylexjs/stylex/lib/StyleXTypes';

export interface IVideoElementProps {
  url: string;
  style: Stylex$Create;
}

export const VideoElement = ({ url, style }: IVideoElementProps) => {
  return (
    <div>
      <video {...style} autoPlay muted loop playsInline src={url}></video>
    </div>
  );
};
