import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';
export const styles = stylex.create({
  photoTextContainer: (reverseOrder) => ({
    display: 'flex',
    padding: reverseOrder ? '0rem 11.0416667rem 0rem 3.125rem' : '0rem 3.125rem 0rem 11.0416667rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: reverseOrder ? 'flex-start' : 'flex-end',
      padding: '0rem',
      gap: '8.53333333rem',
    },
  }),
  textContainer: (reverseOrder) => ({
    display: 'flex',
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontStyle: 'normal',
    fontWeight: '300',
    width: '30.4166667rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.08333333rem',
    order: reverseOrder ? '2' : '1',
    '@media (max-width: 767px)': {
      margin: '0rem 4rem 0rem 4.26666667rem',
      gap: '6.4rem',
      alignSelf: 'stretch',
      width: '91.7333333rem',
      order: '2',
    },
  }),
  h3: {
    alignSelf: 'stretch',
    fontSize: '1.875rem',
    lineHeight: '2.08333333rem',
    fontWeight: '300',
    '@media (max-width: 767px)': {
      fontSize: '6.4rem',
      lineHeight: '7.46666667rem',
    },
  },
  descriptionBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.83333rem',
    '@media (max-width: 767px)': {
      gap: '4.26667rem',
    },
  },
  descriptionContainer: {
    alignSelf: 'stretch',
    fontSize: '1.04166667rem',
    lineHeight: '1.45833333rem',
    '@media (max-width: 767px)': {
      fontSize: '4.26666667rem',
      lineHeight: '6.4rem',
    },
  },
  photoFrameContainer: (reverseOrder) => ({
    display: 'flex',
    width: '46.25rem',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '0.41666667rem',
    order: reverseOrder ? '1' : '2',
    '@media (max-width: 767px)': {
      width: '95.7333333rem',
      gap: '2.13333333rem',
      order: '1',
    },
  }),
  img: (reverseOrder) => ({
    width: '46.25rem',
    height: '30.2083333rem',
    objectFit: 'cover',
    objectPosition: 'top',
    borderRadius: reverseOrder ? '0rem 2.91666667rem 0rem 0rem' : '0rem 0rem 0rem 2.91666667rem',
    '@media (max-width: 767px)': {
      width: '95.7333333rem',
      height: '68.928rem',
      borderRadius: reverseOrder ? '0rem 14.9333333rem 0rem 0rem' : '0px 0px 0px 14.9333333rem',
    },
  }),
  captionContainer: {
    color: colors.secondaryText,
    textAlign: 'right',
    fontFamily: fonts.din2014Light,
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.25rem',
    width: '100%',
    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
      lineHeight: '4.26666667rem',
      paddingRight: '4.26666667rem',
    },
  },
});
