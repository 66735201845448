import stylex from '@stylexjs/stylex';
import { styles } from './styles';

export interface ITextParagraph {
  header: string;
  details: string;
}
export interface ITextParagraphProps {
  block: ITextParagraph;
}

export const TextParagraph = ({ block }: ITextParagraphProps) => {
  return (
    <div {...stylex.props(styles.block)} id="Мобильное приложение">
      <div {...stylex.props(styles.header)}>{block.header}</div>
      <div {...stylex.props(styles.details)}>{block.details}</div>
    </div>
  );
};
