import React from 'react';
import styles from './styles.module.css';

interface LinkButtonProps {
  linkForButton?: string;
  buttonText?: string;
  mainColor?: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ linkForButton, buttonText, mainColor }) => {
  return (
    (linkForButton || buttonText) && (
      <a
        href={linkForButton ? linkForButton : '#'}
        target="_blank"
        className={styles.button}
        style={{ borderColor: mainColor ? mainColor : '' }}
        rel="noopener noreferrer"
        draggable="false"
      >
        {buttonText && (
          <p className={styles.buttonText} style={{ color: mainColor ? mainColor : '' }}>
            {buttonText}
          </p>
        )}
      </a>
    )
  );
};
