import { PortfolioProps } from '../model/types';

import LogoObydenskiy from '@/shared/ui/assets/portfolio/logoObydenskiy.svg?react';
import pointObydenskiy from '@/shared/ui/assets/portfolio/pointObydenskiy.svg';
import imgObydenskiy from '@/shared/ui/assets/portfolio/imgObydenskiy.jpg';
import photoObydenskiy1 from '@/shared/ui/assets/portfolio/photoObydenskiy1.jpg';
import photoObydenskiy2 from '@/shared/ui/assets/portfolio/photoObydenskiy2.jpg';
import photoObydenskiy3 from '@/shared/ui/assets/portfolio/photoObydenskiy3.jpg';
import photoObydenskiy4 from '@/shared/ui/assets/portfolio/photoObydenskiy4.jpg';
import photoObydenskiy5 from '@/shared/ui/assets/portfolio/photoObydenskiy5.jpg';
import photoObydenskiy6 from '@/shared/ui/assets/portfolio/photoObydenskiy6.jpg';

import LogoTishinsky from '@/shared/ui/assets/portfolio/logoTishinsky.svg?react';
import pointTishinsky from '@/shared/ui/assets/portfolio/pointTishinsky.svg';
import imgTishinsky from '@/shared/ui/assets/portfolio/imgTishinsky.jpg';
import photoTishinsky1 from '@/shared/ui/assets/portfolio/photoTishinsky1.jpg';
import photoTishinsky2 from '@/shared/ui/assets/portfolio/photoTishinsky2.png';
import photoTishinsky3 from '@/shared/ui/assets/portfolio/photoTishinsky3.png';
import photoTishinsky4 from '@/shared/ui/assets/portfolio/photoTishinsky4.jpg';
import photoTishinsky5 from '@/shared/ui/assets/portfolio/photoTishinsky5.png';
import photoTishinsky6 from '@/shared/ui/assets/portfolio/photoTishinsky6.jpg';
import photoTishinsky7 from '@/shared/ui/assets/portfolio/photoTishinsky7.jpg';
import photoTishinsky8 from '@/shared/ui/assets/portfolio/photoTishinsky8.jpg';
import photoTishinsky9 from '@/shared/ui/assets/portfolio/photoTishinsky9.jpg';

import LogoFrunzenskaya from '@/shared/ui/assets/portfolio/logoFrunzenskaya.svg?react';
import pointFrunzenskaya from '@/shared/ui/assets/portfolio/pointFrunzenskaya.svg';
import imgFrunzenskaya from '@/shared/ui/assets/portfolio/imgFrunzenskaya.jpg';
import photoFrunzenskaya1 from '@/shared/ui/assets/portfolio/photoFrunzenskaya1.jpg';
import photoFrunzenskaya2 from '@/shared/ui/assets/portfolio/photoFrunzenskaya2.jpg';
import photoFrunzenskaya3 from '@/shared/ui/assets/portfolio/photoFrunzenskaya3.jpg';
import photoFrunzenskaya4 from '@/shared/ui/assets/portfolio/photoFrunzenskaya4.jpg';
import photoFrunzenskaya5 from '@/shared/ui/assets/portfolio/photoFrunzenskaya5.jpg';
import photoFrunzenskaya6 from '@/shared/ui/assets/portfolio/photoFrunzenskaya6.jpg';

import LogoLavrushinsky from '@/shared/ui/assets/portfolio/logoLavrushinsky.svg?react';
import pointLavrushinsky from '@/shared/ui/assets/portfolio/pointLavrushinsky.svg';
import imgLavrushinsky from '@/shared/ui/assets/portfolio/imgLavrushinsky.jpg';
import photoLavrushinsky1 from '@/shared/ui/assets/portfolio/photoLavrushinsky1.jpg';
import photoLavrushinsky2 from '@/shared/ui/assets/portfolio/photoLavrushinsky2.jpg';
import photoLavrushinsky3 from '@/shared/ui/assets/portfolio/photoLavrushinsky3.jpg';
import photoLavrushinsky4 from '@/shared/ui/assets/portfolio/photoLavrushinsky4.jpg';
import photoLavrushinsky5 from '@/shared/ui/assets/portfolio/photoLavrushinsky5.jpg';
import photoLavrushinsky6 from '@/shared/ui/assets/portfolio/photoLavrushinsky6.jpg';
import photoLavrushinsky7 from '@/shared/ui/assets/portfolio/photoLavrushinsky7.jpg';
import photoLavrushinsky8 from '@/shared/ui/assets/portfolio/photoLavrushinsky8.jpg';
import photoLavrushinsky9 from '@/shared/ui/assets/portfolio/photoLavrushinsky9.jpg';
import photoLavrushinsky10 from '@/shared/ui/assets/portfolio/photoLavrushinsky10.jpg';
import photoLavrushinsky11 from '@/shared/ui/assets/portfolio/photoLavrushinsky11.jpg';

import LogoKhameleon from '@/shared/ui/assets/portfolio/logoKhameleon.svg?react';
import pointKhameleon from '@/shared/ui/assets/portfolio/pointKhameleon.svg';
import imgKhameleon from '@/shared/ui/assets/portfolio/imgKhameleon.jpg';
import photoKhameleon1 from '@/shared/ui/assets/portfolio/photoKhameleon1.jpg';
import photoKhameleon2 from '@/shared/ui/assets/portfolio/photoKhameleon2.jpg';
import photoKhameleon3 from '@/shared/ui/assets/portfolio/photoKhameleon3.jpg';
import photoKhameleon4 from '@/shared/ui/assets/portfolio/photoKhameleon4.jpg';
import photoKhameleon5 from '@/shared/ui/assets/portfolio/photoKhameleon5.jpg';

import LogoOrdynka from '@/shared/ui/assets/portfolio/logoOrdynka.svg?react';
import pointOrdynka from '@/shared/ui/assets/portfolio/pointOrdynka.svg';
import imgOrdynka from '@/shared/ui/assets/portfolio/imgOrdynka.jpg';
import photoOrdynka1 from '@/shared/ui/assets/portfolio/photoOrdynka1.jpg';
import photoOrdynka2 from '@/shared/ui/assets/portfolio/photoOrdynka2.jpg';
import photoOrdynka3 from '@/shared/ui/assets/portfolio/photoOrdynka3.jpg';
import photoOrdynka4 from '@/shared/ui/assets/portfolio/photoOrdynka4.jpg';
import photoOrdynka5 from '@/shared/ui/assets/portfolio/photoOrdynka5.jpg';

import LogoJazz from '@/shared/ui/assets/portfolio/logoJazz.svg?react';
import pointJazz from '@/shared/ui/assets/portfolio/pointJazz.svg';
import imgJazz from '@/shared/ui/assets/portfolio/imgJazz.jpg';
import photoJazz1 from '@/shared/ui/assets/portfolio/photoJazz1.jpg';
import photoJazz2 from '@/shared/ui/assets/portfolio/photoJazz2.jpg';
import photoJazz3 from '@/shared/ui/assets/portfolio/photoJazz3.jpg';
import photoJazz4 from '@/shared/ui/assets/portfolio/photoJazz4.jpg';
import photoJazz5 from '@/shared/ui/assets/portfolio/photoJazz5.jpg';

import LogoRenome from '@/shared/ui/assets/portfolio/logoRenome.svg?react';
import pointRenome from '@/shared/ui/assets/portfolio/pointRenome.svg';
import imgRenome from '@/shared/ui/assets/portfolio/imgRenome.jpg';
import photoRenome1 from '@/shared/ui/assets/portfolio/photoRenome1.jpg';
import photoRenome2 from '@/shared/ui/assets/portfolio/photoRenome2.jpg';
import photoRenome3 from '@/shared/ui/assets/portfolio/photoRenome3.jpg';
import photoRenome4 from '@/shared/ui/assets/portfolio/photoRenome4.jpg';
import photoRenome5 from '@/shared/ui/assets/portfolio/photoRenome5.jpg';

import LogoSeasons from '@/shared/ui/assets/portfolio/logoSeasons.svg?react';
import pointSeasons from '@/shared/ui/assets/portfolio/pointSeasons.svg';
import imgSeasons from '@/shared/ui/assets/portfolio/imgSeasons.jpg';
import photoSeasons1 from '@/shared/ui/assets/portfolio/photoSeasons1.jpg';
import photoSeasons2 from '@/shared/ui/assets/portfolio/photoSeasons2.jpg';
import photoSeasons3 from '@/shared/ui/assets/portfolio/photoSeasons3.jpg';

import LogoYard from '@/shared/ui/assets/portfolio/logoYard.svg?react';
import pointYard from '@/shared/ui/assets/portfolio/pointYard.svg';
import imgYard from '@/shared/ui/assets/portfolio/imgYard.jpg';
import photoYard1 from '@/shared/ui/assets/portfolio/photoYard1.jpeg';
import photoYard2 from '@/shared/ui/assets/portfolio/photoYard2.jpeg';
import photoYard3 from '@/shared/ui/assets/portfolio/photoYard3.jpg';
import photoYard4 from '@/shared/ui/assets/portfolio/photoYard4.jpg';

import LogoSmartpark from '@/shared/ui/assets/portfolio/logoSmartpark.svg?react';
import pointSmartpark from '@/shared/ui/assets/portfolio/pointSmartpark.svg';
import imgSmartpark from '@/shared/ui/assets/portfolio/imgSmartpark.jpg';
import photoSmartpark1 from '@/shared/ui/assets/portfolio/photoSmartpark1.jpg';
import photoSmartpark2 from '@/shared/ui/assets/portfolio/photoSmartpark2.jpg';
import photoSmartpark3 from '@/shared/ui/assets/portfolio/photoSmartpark3.jpg';
import photoSmartpark4 from '@/shared/ui/assets/portfolio/photoSmartpark4.jpg';
import photoSmartpark5 from '@/shared/ui/assets/portfolio/photoSmartpark5.jpg';

import LogoAtmosphere from '@/shared/ui/assets/portfolio/logoAtmosphere.svg?react';
import pointAtmosphere from '@/shared/ui/assets/portfolio/pointAtmosphere.svg';
import imgAtmosphere from '@/shared/ui/assets/portfolio/imgAtmosphere.jpg';
import photoAtmosphere1 from '@/shared/ui/assets/portfolio/photoAtmosphere1.jpg';
import photoAtmosphere2 from '@/shared/ui/assets/portfolio/photoAtmosphere2.jpg';
import photoAtmosphere3 from '@/shared/ui/assets/portfolio/photoAtmosphere3.jpg';
import photoAtmosphere4 from '@/shared/ui/assets/portfolio/photoAtmosphere4.jpg';
import photoAtmosphere5 from '@/shared/ui/assets/portfolio/photoAtmosphere5.jpg';

import LogoBankside from '@/shared/ui/assets/portfolio/logoBankside.svg?react';
import pointBankside from '@/shared/ui/assets/portfolio/pointBankside.svg';
import imgBankside from '@/shared/ui/assets/portfolio/imgBankside.jpg';
import photoBankside1 from '@/shared/ui/assets/portfolio/photoBankside1.jpg';
import photoBankside2 from '@/shared/ui/assets/portfolio/photoBankside2.jpg';
import photoBankside3 from '@/shared/ui/assets/portfolio/photoBankside3.jpg';
import photoBankside4 from '@/shared/ui/assets/portfolio/photoBankside4.jpg';
import photoBankside5 from '@/shared/ui/assets/portfolio/photoBankside5.jpg';

import LogoBerzarina from '@/shared/ui/assets/portfolio/logoBerzarina.svg?react';
import pointBerzarina from '@/shared/ui/assets/portfolio/pointBerzarina.svg';
import imgBerzarina from '@/shared/ui/assets/portfolio/imgBerzarina.jpg';
import photoBerzarina1 from '@/shared/ui/assets/portfolio/photoBerzarina1.jpg';
import photoBerzarina2 from '@/shared/ui/assets/portfolio/photoBerzarina2.jpg';
import photoBerzarina3 from '@/shared/ui/assets/portfolio/photoBerzarina3.jpg';
import photoBerzarina4 from '@/shared/ui/assets/portfolio/photoBerzarina4.jpg';
import photoBerzarina5 from '@/shared/ui/assets/portfolio/photoBerzarina5.jpg';

import LogoparkMira from '@/shared/ui/assets/portfolio/logoparkMira.svg?react';
import pointparkMira from '@/shared/ui/assets/portfolio/pointparkMira.svg';
import imgparkMira from '@/shared/ui/assets/portfolio/imgparkMira.jpg';
import photoparkMira1 from '@/shared/ui/assets/portfolio/photoparkMira1.jpg';
import photoparkMira2 from '@/shared/ui/assets/portfolio/photoparkMira2.jpeg';
import photoparkMira3 from '@/shared/ui/assets/portfolio/photoparkMira3.jpg';
import photoparkMira4 from '@/shared/ui/assets/portfolio/photoparkMira4.jpg';
import photoparkMira5 from '@/shared/ui/assets/portfolio/photoparkMira5.jpg';

import LogoChistyePrudy from '@/shared/ui/assets/portfolio/logoChistyePrudy.svg?react';
import pointChistyePrudy from '@/shared/ui/assets/portfolio/pointChistyePrudy.svg';
import imgChistyePrudy from '@/shared/ui/assets/portfolio/imgChistyePrudy.jpg';
import photoChistyePrudy1 from '@/shared/ui/assets/portfolio/photoChistyePrudy1.jpg';
import photoChistyePrudy2 from '@/shared/ui/assets/portfolio/photoChistyePrudy2.jpg';
import photoChistyePrudy3 from '@/shared/ui/assets/portfolio/photoChistyePrudy3.jpg';
import photoChistyePrudy4 from '@/shared/ui/assets/portfolio/photoChistyePrudy4.png';
import photoChistyePrudy5 from '@/shared/ui/assets/portfolio/photoChistyePrudy5.jpg';

import LogoIlyinka from '@/shared/ui/assets/portfolio/logoIlyinka.svg?react';
import pointIlyinka from '@/shared/ui/assets/portfolio/pointIlyinka.svg';
import imgIlyinka from '@/shared/ui/assets/portfolio/imgIlyinka.png';
import photoIlyinka1 from '@/shared/ui/assets/portfolio/photoIlyinka1.jpg';
import photoIlyinka2 from '@/shared/ui/assets/portfolio/photoIlyinka2.jpg';
import photoIlyinka3 from '@/shared/ui/assets/portfolio/photoIlyinka3.jpg';
import photoIlyinka4 from '@/shared/ui/assets/portfolio/photoIlyinka4.jpg';
import photoIlyinka5 from '@/shared/ui/assets/portfolio/photoIlyinka5.jpg';

import LogoLifeTime from '@/shared/ui/assets/portfolio/logoLifeTime.svg?react';
import pointLifeTime from '@/shared/ui/assets/portfolio/pointLifeTime.svg';
import imgLifeTime from '@/shared/ui/assets/portfolio/imgLifeTime.jpg';
import photoLifeTime1 from '@/shared/ui/assets/portfolio/photoLifeTime1.jpg';
import photoLifeTime2 from '@/shared/ui/assets/portfolio/photoLifeTime2.jpg';
import photoLifeTime3 from '@/shared/ui/assets/portfolio/photoLifeTime3.jpg';
import photoLifeTime4 from '@/shared/ui/assets/portfolio/photoLifeTime4.jpg';
import photoLifeTime5 from '@/shared/ui/assets/portfolio/photoLifeTime5.jpg';
import photoLifeTime6 from '@/shared/ui/assets/portfolio/photoLifeTime6.jpg';
import photoLifeTime7 from '@/shared/ui/assets/portfolio/photoLifeTime7.jpg';

import LogoWestgarden from '@/shared/ui/assets/portfolio/logoWestgarden.svg?react';
import pointWestgarden from '@/shared/ui/assets/portfolio/pointWestgarden.svg';
import imgWestgarden from '@/shared/ui/assets/portfolio/imgWestgarden.jpg';
import photoWestgarden1 from '@/shared/ui/assets/portfolio/photoWestgarden1.jpg';
import photoWestgarden2 from '@/shared/ui/assets/portfolio/photoWestgarden2.jpg';
import photoWestgarden3 from '@/shared/ui/assets/portfolio/photoWestgarden3.jpg';
import photoWestgarden4 from '@/shared/ui/assets/portfolio/photoWestgarden4.jpg';
import photoWestgarden5 from '@/shared/ui/assets/portfolio/photoWestgarden5.jpg';

import LogoDostigenie from '@/shared/ui/assets/portfolio/logoDostigenie.svg?react';
import pointDostigenie from '@/shared/ui/assets/portfolio/pointDostigenie.svg';
import imgDostigenie from '@/shared/ui/assets/portfolio/imgDostigenie.jpg';
import photoDostigenie1 from '@/shared/ui/assets/portfolio/photoDostigenie1.jpg';
import photoDostigenie2 from '@/shared/ui/assets/portfolio/photoDostigenie2.jpg';
import photoDostigenie3 from '@/shared/ui/assets/portfolio/photoDostigenie3.jpg';
import photoDostigenie4 from '@/shared/ui/assets/portfolio/photoDostigenie4.jpg';
import photoDostigenie5 from '@/shared/ui/assets/portfolio/photoDostigenie5.jpg';
import photoDostigenie6 from '@/shared/ui/assets/portfolio/photoDostigenie6.jpg';
import photoDostigenie7 from '@/shared/ui/assets/portfolio/photoDostigenie7.jpg';
import photoDostigenie8 from '@/shared/ui/assets/portfolio/photoDostigenie8.jpg';
import photoDostigenie9 from '@/shared/ui/assets/portfolio/photoDostigenie9.jpg';
import photoDostigenie10 from '@/shared/ui/assets/portfolio/photoDostigenie10.jpg';

import LogoGorodskayaUsadba from '@/shared/ui/assets/portfolio/logoGorodskayaUsadba.svg?react';
import pointGorodskayaUsadba from '@/shared/ui/assets/portfolio/pointGorodskayaUsadba.svg';
import imgGorodskayaUsadba from '@/shared/ui/assets/portfolio/imgGorodskayaUsadba.jpg';
import photoGorodskayaUsadba1 from '@/shared/ui/assets/portfolio/photoGorodskayaUsadba1.jpg';
import photoGorodskayaUsadba2 from '@/shared/ui/assets/portfolio/photoGorodskayaUsadba2.jpg';
import photoGorodskayaUsadba3 from '@/shared/ui/assets/portfolio/photoGorodskayaUsadba3.jpg';
import photoGorodskayaUsadba4 from '@/shared/ui/assets/portfolio/photoGorodskayaUsadba4.jpg';
import photoGorodskayaUsadba5 from '@/shared/ui/assets/portfolio/photoGorodskayaUsadba5.jpg';
import photoGorodskayaUsadba6 from '@/shared/ui/assets/portfolio/photoGorodskayaUsadba6.jpg';

import LogoVrubel from '@/shared/ui/assets/portfolio/logoVrubel.svg?react';
import pointVrubel from '@/shared/ui/assets/portfolio/pointVrubel.svg';
import imgVrubel from '@/shared/ui/assets/portfolio/imgVrubel.jpg';
import photoVrubel1 from '@/shared/ui/assets/portfolio/photoVrubel1.jpg';
import photoVrubel2 from '@/shared/ui/assets/portfolio/photoVrubel2.jpg';

import LogoSavvinskayaBC from '@/shared/ui/assets/portfolio/logoSavvinskayaBC.svg?react';
import pointSavvinskayaBC from '@/shared/ui/assets/portfolio/pointSavvinskayaBC.svg';
import imgSavvinskayaBC from '@/shared/ui/assets/portfolio/imgSavvinskayaBC.png';
import photoSavvinskayaBC1 from '@/shared/ui/assets/portfolio/photoSavvinskayaBC1.png';
import photoSavvinskayaBC2 from '@/shared/ui/assets/portfolio/photoSavvinskayaBC2.jpg';

import LogoTwist from '@/shared/ui/assets/portfolio/logoTwist.svg?react';
import pointTwist from '@/shared/ui/assets/portfolio/pointTwist.svg';
import imgTwist from '@/shared/ui/assets/portfolio/imgTwist.jpg';
import photoTwist1 from '@/shared/ui/assets/portfolio/photoTwist1.jpg';
import photoTwist2 from '@/shared/ui/assets/portfolio/photoTwist2.jpg';
import photoTwist3 from '@/shared/ui/assets/portfolio/photoTwist3.jpg';
import photoTwist4 from '@/shared/ui/assets/portfolio/photoTwist4.jpg';
import photoTwist5 from '@/shared/ui/assets/portfolio/photoTwist5.jpg';

import LogoWesterdam from '@/shared/ui/assets/portfolio/logoWesterdam.svg?react';
import pointWesterdam from '@/shared/ui/assets/portfolio/pointWesterdam.svg';
import imgWesterdam from '@/shared/ui/assets/portfolio/imgWesterdam.jpg';
import photoWesterdam1 from '@/shared/ui/assets/portfolio/photoWesterdam1.jpg';
import photoWesterdam2 from '@/shared/ui/assets/portfolio/photoWesterdam2.jpg';
import photoWesterdam3 from '@/shared/ui/assets/portfolio/photoWesterdam3.jpg';
import photoWesterdam4 from '@/shared/ui/assets/portfolio/photoWesterdam4.jpg';
import photoWesterdam5 from '@/shared/ui/assets/portfolio/photoWesterdam5.jpg';

export const PORTFOLIO_MOCK_DATA: PortfolioProps = [
  {
    name: 'Фрунзенская набережная',
    description: 'Элитный клубный<br/>город-парк в Москве', // Слоган
    logo: LogoFrunzenskaya, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 1,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Фрунзенская набережная 1', src: photoFrunzenskaya1 },
      { alt: 'Фрунзенская набережная 2', src: photoFrunzenskaya2 },
      { alt: 'Фрунзенская набережная 3', src: photoFrunzenskaya3 },
      { alt: 'Фрунзенская набережная 4', src: photoFrunzenskaya4 },
      { alt: 'Фрунзенская набережная 5', src: photoFrunzenskaya5 },
      { alt: 'Фрунзенская набережная 6', src: photoFrunzenskaya6 },
    ],
    url: '/frunzenskaya',
    map: {
      latLng: [55.724591, 37.584658],
      pointImg: pointFrunzenskaya, // Указатель на карте
      imagePath: imgFrunzenskaya, // Картинка для анонса
      address: 'Фрунзенская набережная', // Адрес
      signature: '', // Подпись
    },
  },
  {
    name: 'Тишинский бульвар',
    description: 'Тихий зеленый элитный квартал', // Слоган
    logo: LogoTishinsky, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 2,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Тишинский бульвар 1', src: photoTishinsky1 },
      { alt: 'Тишинский бульвар 2', src: photoTishinsky2 },
      { alt: 'Тишинский бульвар 3', src: photoTishinsky3 },
      { alt: 'Тишинский бульвар 4', src: photoTishinsky4 },
      { alt: 'Тишинский бульвар 5', src: photoTishinsky5 },
      { alt: 'Тишинский бульвар 6', src: photoTishinsky6 },
      { alt: 'Тишинский бульвар 7', src: photoTishinsky7 },
      { alt: 'Тишинский бульвар 8', src: photoTishinsky8 },
      { alt: 'Тишинский бульвар 9', src: photoTishinsky9 },
    ],
    url: '/tishinsky',
    map: {
      latLng: [55.772375, 37.574687],
      pointImg: pointTishinsky, // Указатель на карте
      imagePath: imgTishinsky, // Картинка для анонса
      address: 'Электрический переулок, 1', // Адрес
      signature: 'Жить на природе, оставаясь в городе', // Подпись
    },
  },
  {
    name: 'Лаврушинский',
    description: 'Бескомпромиссный дом с видом на кремль', // Слоган
    logo: LogoLavrushinsky, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 3,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Лаврушинский 1', src: photoLavrushinsky1 },
      { alt: 'Лаврушинский 2', src: photoLavrushinsky2 },
      { alt: 'Лаврушинский 3', src: photoLavrushinsky3 },
      { alt: 'Лаврушинский 4', src: photoLavrushinsky4 },
      { alt: 'Лаврушинский 5', src: photoLavrushinsky5 },
      { alt: 'Лаврушинский 6', src: photoLavrushinsky6 },
      { alt: 'Лаврушинский 7', src: photoLavrushinsky7 },
      { alt: 'Лаврушинский 8', src: photoLavrushinsky8 },
      { alt: 'Лаврушинский 9', src: photoLavrushinsky9 },
      { alt: 'Лаврушинский 10', src: photoLavrushinsky10 },
      { alt: 'Лаврушинский 11', src: photoLavrushinsky11 },
    ],
    url: '/lavrushinsky',
    map: {
      latLng: [55.739822201431, 37.620712242151],
      pointImg: pointLavrushinsky, // Указатель на карте
      imagePath: imgLavrushinsky, // Картинка для анонса
      address: 'Б. Толмачевский пер., 5', // Адрес
      signature: 'Дом с видом на Кремль', // Подпись
    },
  },
  {
    name: 'Обыденский №1',
    description: 'Самый правильный дом в лучшей части остоженки', // Слоган
    logo: LogoObydenskiy, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 4,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Обыденский 1', src: photoObydenskiy1 },
      { alt: 'Обыденский 2', src: photoObydenskiy2 },
      { alt: 'Обыденский 3', src: photoObydenskiy3 },
      { alt: 'Обыденский 4', src: photoObydenskiy4 },
      { alt: 'Обыденский 5', src: photoObydenskiy5 },
      { alt: 'Обыденский 6', src: photoObydenskiy6 },
    ],
    url: '/obydenskiy',
    map: {
      latLng: [55.742128, 37.604495],
      pointImg: pointObydenskiy, // Указатель на карте
      imagePath: imgObydenskiy, // Картинка для анонса
      address: '3-й Обыденский переулок, 1', // Адрес
      signature: '', // Подпись
    },
  },
  {
    name: 'Чистые Пруды',
    description: 'В шаге от воды, в окружении тишины', // Слоган
    logo: LogoChistyePrudy, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 5,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'ChistyePrudy 1', src: photoChistyePrudy1 },
      { alt: 'ChistyePrudy 2', src: photoChistyePrudy2 },
      { alt: 'ChistyePrudy 3', src: photoChistyePrudy3 },
      { alt: 'ChistyePrudy 4', src: photoChistyePrudy4 },
      { alt: 'ChistyePrudy 5', src: photoChistyePrudy5 },
    ],
    url: '/chistye-prudy',
    map: {
      latLng: [55.7616986, 37.6411615],
      pointImg: pointChistyePrudy, // Указатель на карте
      imagePath: imgChistyePrudy, // Картинка для анонса
      address: 'Потаповский переулок, 5, стр. 4', // Адрес
      signature: 'Клубный дом', // Подпись
    },
  },
  {
    name: 'Ильинка, 3/8',
    description: 'Самые близкие к Кремлю резиденции в клубных особняках', // Слоган
    logo: LogoIlyinka, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 6,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Ilyinka 1', src: photoIlyinka1 },
      { alt: 'Ilyinka 2', src: photoIlyinka2 },
      { alt: 'Ilyinka 3', src: photoIlyinka3 },
      { alt: 'Ilyinka 4', src: photoIlyinka4 },
      { alt: 'Ilyinka 5', src: photoIlyinka5 },
    ],
    url: '/ilyinka',
    map: {
      latLng: [55.755569, 37.624115],
      pointImg: pointIlyinka, // Указатель на карте
      imagePath: imgIlyinka, // Картинка для анонса
      address: 'ул. Ильинка, 3/8', // Адрес
      signature: '', // Подпись
    },
  },
  {
    name: 'Life Time',
    description: 'Роскошный квартал дарит время для жизни', // Слоган
    logo: LogoLifeTime, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 7,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'LifeTime 1', src: photoLifeTime1 },
      { alt: 'LifeTime 2', src: photoLifeTime2 },
      { alt: 'LifeTime 3', src: photoLifeTime3 },
      { alt: 'LifeTime 4', src: photoLifeTime4 },
      { alt: 'LifeTime 5', src: photoLifeTime5 },
      { alt: 'LifeTime 6', src: photoLifeTime6 },
      { alt: 'LifeTime 7', src: photoLifeTime7 },
    ],
    url: '/life-time',
    map: {
      latLng: [55.762575970744, 37.549462711639],
      pointImg: pointLifeTime, // Указатель на карте
      imagePath: imgLifeTime, // Картинка для анонса
      address: 'ул. Сергея Макеева, вл. 11', // Адрес
      signature: '', // Подпись
    },
  },
  {
    name: 'West Garden',
    description: 'Собрание клубных домов в своем парке 16 га вдоль реки', // Слоган
    logo: LogoWestgarden, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 8,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Westgarden 1', src: photoWestgarden1 },
      { alt: 'Westgarden 2', src: photoWestgarden2 },
      { alt: 'Westgarden 3', src: photoWestgarden3 },
      { alt: 'Westgarden 4', src: photoWestgarden4 },
      { alt: 'Westgarden 5', src: photoWestgarden5 },
    ],
    url: '/westgarden',
    map: {
      latLng: [55.716326, 37.497953],
      pointImg: pointWestgarden, // Указатель на карте
      imagePath: imgWestgarden, // Картинка для анонса
      address: 'ул. Минская, д.1ГсА', // Адрес
      signature: 'Жить на природе, оставаясь в городе', // Подпись
    },
  },
  {
    name: 'Достижение',
    description: 'Выдающийся дом у выдающийся парков', // Слоган
    logo: LogoDostigenie, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 9,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Dostigenie 1', src: photoDostigenie1 },
      { alt: 'Dostigenie 2', src: photoDostigenie2 },
      { alt: 'Dostigenie 3', src: photoDostigenie3 },
      { alt: 'Dostigenie 4', src: photoDostigenie4 },
      { alt: 'Dostigenie 5', src: photoDostigenie5 },
      { alt: 'Dostigenie 6', src: photoDostigenie6 },
      { alt: 'Dostigenie 6', src: photoDostigenie7 },
      { alt: 'Dostigenie 6', src: photoDostigenie8 },
      { alt: 'Dostigenie 6', src: photoDostigenie9 },
      { alt: 'Dostigenie 6', src: photoDostigenie10 },
    ],
    url: '/dostigenie',
    map: {
      latLng: [55.820023634087, 37.602411510173],
      pointImg: pointDostigenie, // Указатель на карте
      imagePath: imgDostigenie, // Картинка для анонса
      address: 'ул. Академика Королёва, 21', // Адрес
      signature: 'Выдающийся дом у выдающихся парков', // Подпись
    },
  },
  {
    name: 'Городская усадьба в Орлово-Давыдовском',
    description: 'Усадьба в центре Москвы с собственным парком 2 гектара', // Слоган
    logo: LogoGorodskayaUsadba, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 10,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'GorodskayaUsadba 1', src: photoGorodskayaUsadba1 },
      { alt: 'GorodskayaUsadba 2', src: photoGorodskayaUsadba2 },
      { alt: 'GorodskayaUsadba 3', src: photoGorodskayaUsadba3 },
      { alt: 'GorodskayaUsadba 4', src: photoGorodskayaUsadba4 },
      { alt: 'GorodskayaUsadba 5', src: photoGorodskayaUsadba5 },
      { alt: 'GorodskayaUsadba 6', src: photoGorodskayaUsadba6 },
    ],
    url: '/gorodskaya-usadba',
    map: {
      latLng: [55.782732384583, 37.637036888451],
      pointImg: pointGorodskayaUsadba, // Указатель на карте
      imagePath: imgGorodskayaUsadba, // Картинка для анонса
      address: 'пер. Орлово-Давыдовский, 2', // Адрес
      signature: '', // Подпись
    },
  },
  {
    name: 'Врубеля, 4',
    description: 'Авторский дом на Соколе. Последняя квартира', // Слоган
    logo: LogoVrubel, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 11,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Vrubel 1', src: photoVrubel1 },
      { alt: 'Vrubel 2', src: photoVrubel2 },
    ],
    url: '/vrubel',
    map: {
      latLng: [55.806277, 37.502076],
      pointImg: pointVrubel, // Указатель на карте
      imagePath: imgVrubel, // Картинка для анонса
      address: 'ул. Врубеля, 4', // Адрес
      signature: '', // Подпись
    },
  },
  {
    name: 'БЦ на Саввинской набережной',
    description: 'Бизнесцентр на савинской набережной', // Слоган
    logo: LogoSavvinskayaBC, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 12,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'SavvinskayaBC 1', src: photoSavvinskayaBC1 },
      { alt: 'SavvinskayaBC 2', src: photoSavvinskayaBC2 },
    ],
    url: '/savvinskaya-bc',
    map: {
      latLng: [55.731039, 37.560691],
      pointImg: pointSavvinskayaBC, // Указатель на карте
      imagePath: imgSavvinskayaBC, // Картинка для анонса
      address: 'Большой Саввинский пер., д.12, стр.16', // Адрес
      signature: '', // Подпись
    },
  },
  {
    name: 'westerdam',
    description: 'Готовые квартиры в ЗАО у метро', // Слоган
    logo: LogoWesterdam, // Лого
    status: '', // Статус
    statusText: 'Продаем машино-место', // Статус текст
    sort: 13,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Westerdam 1', src: photoWesterdam1 },
      { alt: 'Westerdam 2', src: photoWesterdam2 },
      { alt: 'Westerdam 3', src: photoWesterdam3 },
      { alt: 'Westerdam 4', src: photoWesterdam4 },
      { alt: 'Westerdam 5', src: photoWesterdam5 },
    ],
    url: '/westerdam',
    map: {
      latLng: [55.699548, 37.462541],
      pointImg: pointWesterdam, // Указатель на карте
      imagePath: imgWesterdam, // Картинка для анонса
      address: 'Аминьевское шоссе, д. 4А', // Адрес
      signature: '', // Подпись
    },
  },
  {
    name: 'TWIST',
    description: 'Футуристичный динамичный деловой', // Слоган
    logo: LogoTwist, // Лого
    status: 'Продаем', // Статус
    statusText: 'Продаём новый дом', // Статус текст
    sort: 14,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Twist 1', src: photoTwist1 },
      { alt: 'Twist 2', src: photoTwist2 },
      { alt: 'Twist 3', src: photoTwist3 },
      { alt: 'Twist 4', src: photoTwist4 },
      { alt: 'Twist 5', src: photoTwist5 },
    ],
    url: '/twist',
    map: {
      latLng: [55.793286, 37.585469],
      pointImg: pointTwist, // Указатель на карте
      imagePath: imgTwist, // Картинка для анонса
      address: 'ул. Бутырская, уч. 1', // Адрес
      signature: '', // Подпись
    },
  },
  {
    name: 'Малая Ордынка, 19',
    description: 'В самом центре, в самой тишине',
    logo: LogoOrdynka,
    status: 'Управляем',
    statusText: 'Управляем домом',
    sort: 15,
    photos: [
      { alt: 'Малая Ордынка 1', src: photoOrdynka1 },
      { alt: 'Малая Ордынка 2', src: photoOrdynka2 },
      { alt: 'Малая Ордынка 3', src: photoOrdynka3 },
      { alt: 'Малая Ордынка 4', src: photoOrdynka4 },
      { alt: 'Малая Ордынка 5', src: photoOrdynka5 },
    ],
    url: '/ordynka',
    map: {
      latLng: [55.736994458053, 37.625954775058],
      pointImg: pointOrdynka,
      imagePath: imgOrdynka,
      address: 'ул. Малая Ордынка, д. 19',
      signature: 'Элитный дом',
    },
  },
  {
    name: 'JAZZ',
    description: 'Абсолютный комфорт в каждой детали', // Слоган
    logo: LogoJazz, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 16,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'JAZZ 1', src: photoJazz1 },
      { alt: 'JAZZ 2', src: photoJazz2 },
      { alt: 'JAZZ 3', src: photoJazz3 },
      { alt: 'JAZZ 4', src: photoJazz4 },
      { alt: 'JAZZ 5', src: photoJazz5 },
    ],
    url: '/jazz',
    map: {
      latLng: [55.793461902147, 37.61169790323],
      pointImg: pointJazz, // Указатель на карте
      imagePath: imgJazz, // Картинка для анонса
      address: 'ул. Сущёвский Вал, д. 49', // Адрес
      signature: 'Премиум-квартал', // Подпись
    },
  },
  {
    name: 'Реномэ',
    description: 'Дом, который дарит время', // Слоган
    logo: LogoRenome, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 17,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Реномэ 1', src: photoRenome1 },
      { alt: 'Реномэ 2', src: photoRenome2 },
      { alt: 'Реномэ 3', src: photoRenome3 },
      { alt: 'Реномэ 4', src: photoRenome4 },
      { alt: 'Реномэ 5', src: photoRenome5 },
    ],
    url: '/renome',
    map: {
      latLng: [55.783169426497, 37.598090342381],
      pointImg: pointRenome, // Указатель на карте
      imagePath: imgRenome, // Картинка для анонса
      address: 'ул. Новослободская, 24', // Адрес
      signature: 'Элитный дом', // Подпись
    },
  },
  {
    name: 'Хамелеон',
    description: 'Бизнес-центр «Хамелеон»', // Слоган
    logo: LogoKhameleon, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 18,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Хамелеон 1', src: photoKhameleon1 },
      { alt: 'Хамелеон 2', src: photoKhameleon2 },
      { alt: 'Хамелеон 3', src: photoKhameleon3 },
      { alt: 'Хамелеон 4', src: photoKhameleon4 },
      { alt: 'Хамелеон 5', src: photoKhameleon5 },
    ],
    url: '/hameleon',
    map: {
      latLng: [55.725370074932, 37.760317419238],
      pointImg: pointKhameleon, // Указатель на карте
      imagePath: imgKhameleon, // Картинка для анонса
      address: 'Рязанский проспект, д.10, стр. 18', // Адрес
      signature: 'Бизнес-центр', // Подпись
    },
  },
  {
    name: 'Seasons',
    description: 'Бизнес-центр Seasons', // Слоган
    logo: LogoSeasons, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 19,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Seasons 1', src: photoSeasons1 },
      { alt: 'Seasons 2', src: photoSeasons2 },
      { alt: 'Seasons 3', src: photoSeasons3 },
    ],
    url: '/seasons',
    map: {
      latLng: [55.719688974314, 37.669673474721],
      pointImg: pointSeasons, // Указатель на карте
      imagePath: imgSeasons, // Картинка для анонса
      address: 'Шарикоподшипниковская ул., 4к4А', // Адрес
      signature: 'Торгово-офисная галерея', // Подпись
    },
  },
  {
    name: 'The Yard',
    description: 'Бизнес-центр The Yard', // Слоган
    logo: LogoYard, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 20,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Yard 1', src: photoYard1 },
      { alt: 'Yard 2', src: photoYard2 },
      { alt: 'Yard 3', src: photoYard3 },
      { alt: 'Yard 4', src: photoYard4 },
    ],
    url: '/yard',
    map: {
      latLng: [55.766283382534, 37.526541778129],
      pointImg: pointYard, // Указатель на карте
      imagePath: imgYard, // Картинка для анонса
      address: '1-й Магистральный тупик, д.11', // Адрес
      signature: 'Офисный центр', // Подпись
    },
  },
  {
    name: 'Smart Park',
    description: 'Бизнес-квартал Smart park', // Слоган
    logo: LogoSmartpark, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 21,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Smartpark 1', src: photoSmartpark1 },
      { alt: 'Smartpark 2', src: photoSmartpark2 },
      { alt: 'Smartpark 3', src: photoSmartpark3 },
      { alt: 'Smartpark 4', src: photoSmartpark4 },
      { alt: 'Smartpark 5', src: photoSmartpark5 },
    ],
    url: '/smartpark',
    map: {
      latLng: [55.656368300965, 37.556097455407],
      pointImg: pointSmartpark, // Указатель на карте
      imagePath: imgSmartpark, // Картинка для анонса
      address: 'Научный проезд, д.14А', // Адрес
      signature: 'Бизнес-квартал', // Подпись
    },
  },
  {
    name: 'Атмосфера',
    description: 'Бизнес-квартал «Атмосфера»', // Слоган
    logo: LogoAtmosphere, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 22,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Atmosphere 1', src: photoAtmosphere1 },
      { alt: 'Atmosphere 2', src: photoAtmosphere2 },
      { alt: 'Atmosphere 3', src: photoAtmosphere3 },
      { alt: 'Atmosphere 4', src: photoAtmosphere4 },
      { alt: 'Atmosphere 5', src: photoAtmosphere5 },
    ],
    url: '/atmosphere',
    map: {
      latLng: [55.783096378116, 37.60132776324],
      pointImg: pointAtmosphere, // Указатель на карте
      imagePath: imgAtmosphere, // Картинка для анонса
      address: 'ул. Сущевская, д.25, стр.1', // Адрес
      signature: 'Бизнес-квартал', // Подпись
    },
  },
  {
    name: 'BankSide',
    description: 'Бизнес-квартал высококлассных особняков на набережной яузы', // Слоган
    logo: LogoBankside, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 23,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Bankside 1', src: photoBankside1 },
      { alt: 'Bankside 2', src: photoBankside2 },
      { alt: 'Bankside 3', src: photoBankside3 },
      { alt: 'Bankside 4', src: photoBankside4 },
      { alt: 'Bankside 5', src: photoBankside5 },
    ],
    url: '/bankside',
    map: {
      latLng: [55.749106948901, 37.666186671368],
      pointImg: pointBankside, // Указатель на карте
      imagePath: imgBankside, // Картинка для анонса
      address: 'Наставнический пер., д.17, стр.1', // Адрес
      signature: 'Бизнес-квартал', // Подпись
    },
  },
  {
    name: 'Berzarina, 12',
    description: 'Клубный дом Berzarina, 12', // Слоган
    logo: LogoBerzarina, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 24,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'Berzarina 1', src: photoBerzarina1 },
      { alt: 'Berzarina 2', src: photoBerzarina2 },
      { alt: 'Berzarina 3', src: photoBerzarina3 },
      { alt: 'Berzarina 4', src: photoBerzarina4 },
      { alt: 'Berzarina 5', src: photoBerzarina5 },
    ],
    url: '/berzarina',
    map: {
      latLng: [55.78788, 37.489265],
      pointImg: pointBerzarina, // Указатель на карте
      imagePath: imgBerzarina, // Картинка для анонса
      address: 'ул. Берзарина, 12', // Адрес
      signature: 'Клубный дом', // Подпись
    },
  },
  {
    name: 'Парк Мира',
    description: 'Многофункциональный комплекс «Парк Мира»', // Слоган
    logo: LogoparkMira, // Лого
    status: 'Управляем', // Статус
    statusText: 'Управляем домом', // Статус текст
    sort: 25,
    // Жилая недвижимость - Галерея
    photos: [
      { alt: 'ParkMira 1', src: photoparkMira1 },
      { alt: 'ParkMira 2', src: photoparkMira2 },
      { alt: 'ParkMira 3', src: photoparkMira3 },
      { alt: 'ParkMira 4', src: photoparkMira4 },
      { alt: 'ParkMira 5', src: photoparkMira5 },
    ],
    url: '/parkmira',
    map: {
      latLng: [55.800595, 37.63668],
      pointImg: pointparkMira, // Указатель на карте
      imagePath: imgparkMira, // Картинка для анонса
      address: 'проспект Мира, 102', // Адрес
      signature: 'Многофункциональный комплекс', // Подпись
    },
  },
];

// {
//   name: 'name',
//   description: 'description', // Слоган
//   logo: LogoName, // Лого
//   status: 'status', // Статус
//   statusText: 'statusText', // Статус текст
//   // Жилая недвижимость - Галерея
//   photos: [
//     { alt: 'Name 1', src: photoName1 },
//     { alt: 'Name 2', src: photoName2 },
//     { alt: 'Name 3', src: photoName3 },
//     { alt: 'Name 4', src: photoName4 },
//     { alt: 'Name 5', src: photoName5 },
//     { alt: 'Name 6', src: photoName6 },
//   ],
//   url: '/name',
//   map: {
//     latLng: [123, 123],
//     pointImg: pointName, // Указатель на карте
//     imagePath: imgName, // Картинка для анонса
//     address: 'address', // Адрес
//     signature: 'signature', // Подпись
//   },
// },
