import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ImageType } from '../model/types';

interface TextAndImageWithCaptionProps {
  textContentComponent: React.ReactNode;
  image: ImageType;
}

export const TextAndImageWithCaption: React.FC<TextAndImageWithCaptionProps> = ({ textContentComponent, image }) => {
  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.textContentComponent)}>{textContentComponent}</div>
      <div {...stylex.props(styles.imageWithCaption)}>
        <img src={image.imgSrc} alt={image.altSrc} {...stylex.props(styles.image)} draggable={false} />
        <div {...stylex.props(styles.captionContainer)}>
          <p {...stylex.props(styles.caption)}>{image.imageCaption}</p>
        </div>
      </div>
    </div>
  );
};
