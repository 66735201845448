import React from 'react';
import styles from './styles.module.css';

interface TopDescriptionProps {
  topDescription?: string;
  mainColor?: string;
}

export const TopDescription: React.FC<TopDescriptionProps> = ({ topDescription, mainColor }) => {
  return (
    <>
      {topDescription && (
        <h3 className={styles.topDescription} style={{ color: mainColor ? mainColor : '' }}>
          {topDescription}
        </h3>
      )}
    </>
  );
};
