import React from 'react';
import styles from './styles.module.css';

interface DescriptionWithListProps {
  description?: string;
  descriptionColor?: string;
  listInDescription?: string[];
}

export const DescriptionWithList: React.FC<DescriptionWithListProps> = ({
  description,
  descriptionColor,
  listInDescription,
}) => {
  return (
    <div className={styles.descriptionWithList}>
      {description && (
        <p className={styles.description} style={{ color: descriptionColor ? descriptionColor : '' }}>
          {description}
        </p>
      )}
      {listInDescription && (
        <ul className={styles.ul}>
          {listInDescription.map((element, index) => (
            <li key={index} className={styles.oneRowInList} style={{ color: descriptionColor ? descriptionColor : '' }}>
              <span className={styles.bulletIcon}>
                <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Frame 3311">
                    <circle id="Ellipse 145" cx="14" cy="14" r="4" fill="currentColor" />
                  </g>
                </svg>
              </span>
              <span className={styles.oneRowText}>{element}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
