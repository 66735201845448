import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  block: {
    fontFamily: fonts.din2014Light,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '1.25rem',
    alignSelf: 'stretch',
    '@media (max-width: 767px)': {
      gap: '4.267rem',
    },
  },
  header: {
    fontSize: '1.875rem',
    fontWeight: 300,
    color: colors.primaryText,
    lineHeight: '111%',

    '@media (max-width: 767px)': {
      fontSize: '6.4rem',
      lineHeight: '116%',
    },
  },
  details: {
    fontSize: '1.042rem',
    fontWeight: 300,
    color: colors.primaryText,
    lineHeight: '140%',
    '@media (max-width: 767px)': {
      fontSize: '4.267rem',
      lineHeight: '150%',
    },
  },
});
