import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  block: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.833rem',
  },
  btn: {
    display: 'flex',
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  qr: {
    display: 'none',
    '@media (min-width: 768px)': {
      display: 'block',
      width: '4.583rem',
      height: '4.583rem',
    },
  },
  name: {
    display: 'none',
    fontFamily: fonts.din2014Light,
    fontSize: '1.042rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '140%',
    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
});
