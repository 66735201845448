import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  pageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '4.17rem 0rem 6.25rem 0rem',
    gap: '6.25rem',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    background: colors.primaryBackground,

    '@media (max-width: 767px)': {
      padding: '29.86333rem 0rem 14.93333rem 0rem',
      gap: '14.93333rem',
    },
  },
  topSeparateUppercaseTextWrapper: {
    width: '57.29167rem',
    padding: '0rem 0rem 0rem 11.04167rem',
    alignSelf: 'flex-start',

    '@media (max-width: 767px)': {
      width: '100%',
      padding: '0rem 4rem 0rem 4.26667rem',
    },
  },
  textInBlockWithHalfSliderWrapper: {
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '0rem 4rem 0rem 4.26667rem',
    },
  },
  middleUpperCaseTextWithCaption: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '0rem 11.04167rem',
    gap: '2.08333rem',

    '@media (max-width: 767px)': {
      padding: '0rem 4.26667rem',
      gap: '6.4rem',
    },
  },
  middleUpperCaseText: {
    width: '46.25rem',
    alignSelf: 'flex-start',

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  sectionWithThreeComponents: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  BeforeAfterSliderWrapper: {
    width: '100%',

    '@media (max-width: 767px)': {
      padding: '6.4rem 0rem 6.4rem 0rem',
    },
  },
  textWithHalfSliderWrapper: {
    width: '100%',
    display: 'flex',
    padding: '0rem 3.125rem 0rem 11.04167rem',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',

    '@media (max-width: 767px)': {
      padding: '0rem 0rem 0rem 0rem',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      gap: '14.93333rem',
    },
  },
  animatedTextWrapper: {
    width: '100%',
    padding: '0rem 11.04167rem 0rem 11.04167rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '2.08333rem',

    '@media (max-width: 767px)': {
      padding: '0rem 4.26667rem 0rem  4.26667rem',
      gap: '8.53333rem',
    },
  },
  animatedUpperCaseText: {
    width: '46.25rem',

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  textWithAnimatedUpperCaseTextWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '@media (max-width: 767px)': {
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  sliderFullScreenWrapper: {
    width: '100%',
    padding: '0rem 3.125rem',

    '@media (max-width: 767px)': {
      padding: '0rem 4.26667rem',
    },
  },
  topCircleProgressBarWrapper: {
    width: '100%',
    position: 'relative',
    height: '33.02083rem',

    '@media (max-width: 767px)': {
      position: 'static',
      height: 'auto',
    },
  },
  topCircleProgressBarContainer: {
    width: '100%',
    position: 'absolute',
    top: '-13.85417rem',

    '@media (max-width: 767px)': {
      position: 'static',
      top: 'auto',
    },
  },
  middleCircleProgressBarWrapper: {
    width: '100%',
    position: 'relative',
    height: '37.1875rem',

    '@media (max-width: 767px)': {
      position: 'static',
      height: 'auto',
    },
  },
  middleCircleProgressBarContainer: {
    width: '100%',
    position: 'absolute',
    top: '-9.6875rem',

    '@media (max-width: 767px)': {
      position: 'static',
      top: 'auto',
    },
  },
  bottomCircleProgressBarWrapper: {
    width: '100%',
    position: 'relative',
    height: '47.60417rem',

    '@media (max-width: 767px)': {
      position: 'static',
      height: 'auto',
    },
  },
  bottomCircleProgressBarContainer: {
    width: '100%',
    position: 'absolute',
    top: '-9.6875rem',

    '@media (max-width: 767px)': {
      position: 'static',
      top: 'auto',
    },
  },
  wrapperForCorrectGapCircle100: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '5.83333rem',

    '@media (max-width: 767px)': {
      gap: '14.93333rem',
    },
  },
  wrapperForCorrectGapCircle247: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '3.3333rem',

    '@media (max-width: 767px)': {
      gap: '8.53333rem',
    },
  },
});
