import { PropsWithChildren } from 'react';
import { IArrowsForSlidesProps } from '@/features/before-after-slider/model/types.ts';

import ArrowThinLeftIcon from '@/shared/ui/icons/arrow-thin-left.tsx';
import ArrowThinRightIcon from '@/shared/ui/icons/arrow-thin-right.tsx';

import styles from '@/features/before-after-slider/ui/index.module.css';

const ArrowsForSlides = ({
  photos,
  setCurrentImgIndex,
  buildingIdsDictionary,
  children,
}: PropsWithChildren<IArrowsForSlidesProps>) => {
  const nextImageHandler = () => {
    setCurrentImgIndex((prev) => {
      const buildingIds = Object.keys(buildingIdsDictionary);
      const nextId = prev + 1;
      if (nextId >= photos.length) {
        return photos.filter((el) => !el.isLogo).splice(0, 1)?.[0].id;
      }
      if (buildingIds.includes(String(nextId))) return nextId + 1;
      return nextId;
    });
  };

  const prevImageHandler = () => {
    setCurrentImgIndex((prev) => {
      const buildingIds = Object.keys(buildingIdsDictionary);
      const nextId = prev - 1;
      if (nextId <= 1) return photos.filter((el) => !el.isLogo).splice(-1)?.[0].id;
      if (buildingIds.includes(String(nextId))) return nextId - 1;
      return nextId;
    });
  };

  return (
    <div className={styles.sliderContainer}>
      <button className={styles.leftArrow} onClick={prevImageHandler}>
        <ArrowThinLeftIcon />
      </button>
      <button className={styles.rightArrow} onClick={nextImageHandler}>
        <ArrowThinRightIcon />
      </button>

      <div className={styles.mainPhotosContainer}>{children}</div>
    </div>
  );
};

export default ArrowsForSlides;
