import styles from '@/features/circled-progress-bar/ui/circled-progress-bar.module.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { useEffect, useRef, useState } from 'react';
import { ICircledProgressBar } from '../model/types';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { MobilePath } from './mobile-path';
import { colors } from '@/shared/tokens/colors.stylex';

gsap.registerPlugin(ScrollTrigger);

export const CircledProgressBar = ({ switcherInfoBlocks }: ICircledProgressBar) => {
  const { isMobile } = useIsMobile();
  const [isEndReached, setIsEndReached] = useState(false);
  const pathRef1 = useRef<SVGPathElement | null>(null);
  const pathRef2 = useRef<SVGPathElement | null>(null);
  const pathMobRef = useRef<SVGPathElement | null>(null);

  useEffect(() => {
    const path1 = pathRef1.current;
    const path2 = pathRef2.current;
    if (!path1 || !path2) return;
    const pathLength1 = path1.getTotalLength();
    const pathLength2 = path2.getTotalLength();

    gsap.set(path1, { strokeDasharray: pathLength1, strokeDashoffset: pathLength1 });
    gsap.set(path2, { strokeDasharray: pathLength2, strokeDashoffset: pathLength2 });
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: path2,
        start: 'top center',
        end: 'bottom 65%',
        scrub: true,
        onUpdate: (s) => setIsEndReached(s.progress > 0.5),
      },
    });

    tl.to(path1, { strokeDashoffset: 0, duration: 2 }).to(path2, { strokeDashoffset: 0, duration: 2 });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const lengthMob = pathMobRef.current?.getTotalLength();
      if (!lengthMob) return;
      gsap.set(pathMobRef.current, { strokeDasharray: lengthMob, strokeDashoffset: lengthMob });

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: pathMobRef.current,
          start: 'top center',
          end: 'bottom 55%',
          scrub: true,
          onUpdate: (s) => setIsEndReached(s.progress > 0.5),
        },
      });

      tl.add('start').to(pathMobRef.current, { strokeDashoffset: '0' }, 'start');
    });

    return () => {
      ctx.revert();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.circle}>
        {isMobile ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 375 459"
            className={styles.svgMob}
          >
            <MobilePath strokeColor={colors.brandBackground} />
            <MobilePath strokeColor={colors.primaryIcon} pathRef={pathMobRef} />
          </svg>
        ) : (
          <svg width="100%" height="100%" viewBox="0 0 805 805" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M402.5.5c222,0,402,180,402,402a400.68,400.68,0,0,1-31.39,156"
              stroke={colors.brandBackground}
              fill="none"
            ></path>
            <path
              d="M402.5.5c222,0,402,180,402,402a400.68,400.68,0,0,1-31.39,156"
              stroke={colors.primaryIcon}
              fill="none"
              ref={pathRef1}
            ></path>
            <path d="M403 0C-128.5 22.5 -138.5 779 403 805" stroke={colors.brandBackground} fill="none"></path>
            <path
              d="M403 805C-138.5 779 -128.5 22.5 403 0"
              stroke={colors.primaryIcon}
              fill="none"
              ref={pathRef2}
            ></path>
          </svg>
        )}
        <div className={styles.innerContent}>
          <div className={`${styles.firstDiv} ${isEndReached ? styles.slideOut : styles.slideIn}`}>
            <span className={styles.number}>{switcherInfoBlocks[0].number}</span>
            <span className={styles.text}> {switcherInfoBlocks[0].text}</span>
          </div>
          <div className={`${styles.secondDiv} ${isEndReached ? styles.slideIn : styles.slideOut}`}>
            <span className={styles.number}>{switcherInfoBlocks[1].number}</span>
            <span className={styles.text}> {switcherInfoBlocks[1].text}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
